import React from "react";
import css from "@emotion/css";
import {Text, Row, Push} from "./ui";
import colors from "./ui/colors";
import {FieldWithLabel} from "./Input";
import {IconCaretDown} from "./ui/icons";

const valueStyle = css({
  color: colors.blue2,
  padding: "0.25rem 0",
  transitionProperty: "color",
});
const valueErrorStyle = css({
  color: colors.red,
});

const placeholderStyle = css({
  color: colors.gray7,
});

const selectStyle = css({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  width: "100%",
  opacity: 0,
});

const Select = React.forwardRef(({value, valueToLabel, placeholder, hasErrors, ...rest}, ref) => (
  <Row fillParent css={{position: "relative"}} align="center">
    <Text size={4} css={[valueStyle, hasErrors && valueErrorStyle, !value && placeholderStyle]}>
      {(value && (valueToLabel ? valueToLabel(value) : value)) || placeholder || "Auswählen"}
    </Text>
    <Push />
    <IconCaretDown color="blue2" />
    <select css={selectStyle} value={value || ""} {...rest} ref={ref} />
  </Row>
));

export const SelectWithLabel = React.forwardRef((props, ref) => (
  <FieldWithLabel as={Select} ref={ref} {...props} />
));

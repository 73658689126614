import React from "react";
import {Row, Push, Text} from ".";
import {useClearBrunnenData, useBrunnenData} from "../../models/brunnen";
import TransparentButton from "./TransparentButton";
import css from "@emotion/css";
import mq from "./media-queries";
import {navigate} from "@reach/router";

const occupyStyle = css({
  minWidth: "8rem",
  [mq.mobile]: {minWidth: "2rem"},
});

const OccupySpace = props => <Row css={occupyStyle} {...props} />;
const Logout = () => {
  const clearBrunnen = useClearBrunnenData();

  return (
    <TransparentButton
      onDark
      onClick={() => {
        clearBrunnen();
        navigate("/");
      }}
    >
      Logout
    </TransparentButton>
  );
};

const nameStyles = {
  short: css({
    display: "none",
    [mq.mobile]: {display: "block"},
  }),
  long: css({
    [mq.mobile]: {display: "none"},
  }),
};

const Name = () => {
  const maybeBrunnen = useBrunnenData();
  return maybeBrunnen.value ? (
    <>
      <Text color="white" size={2} css={nameStyles.short}>
        {maybeBrunnen.value.user.shortName}
      </Text>
      <Text color="white" size={2} css={nameStyles.long}>
        {maybeBrunnen.value.user.fullName}
      </Text>
    </>
  ) : null;
};

const titleBarStyle = css({
  position: "sticky",
  top: 0,
  zIndex: 5,
});

const TitleBar = ({left, right, title}) => {
  return (
    <Row sp={2} bg="blue2" py={3} px={4} css={titleBarStyle} elevation={1} align="center">
      <OccupySpace justify="start">{left || <Name />}</OccupySpace>
      <Push />
      <Text color="white">{title}</Text>
      <Push />
      <OccupySpace justify="end">{right || <Logout />}</OccupySpace>
    </Row>
  );
};

export default TitleBar;

import React from "react";
import MainScreenLayout from "../../components/ui/MainScreenLayout";
import {IconStrom} from "../../components/ui/icons";
import TaskList from "../../components/TaskList";

const StromListeScreen = () => (
  <MainScreenLayout>
    <TaskList taskType="STROM" icon={IconStrom} taskToUrl={t => `/tasks/strom/${t.id}`} />
  </MainScreenLayout>
);

export default StromListeScreen;

import {css} from "@emotion/core";

export const classes = {};

export const addClasses = (
  types,
  steps,
  stepToRule = (step, type) => ({[type]: typeof step === "string" ? step : `${step}rem`})
) => {
  const isArr = Array.isArray(steps);
  types.forEach(type => {
    if (isArr) {
      classes[type] = [];
      steps.forEach(step => {
        classes[type].push(css(stepToRule(step, type)));
      });
    } else {
      classes[type] = {};
      Object.entries(steps).forEach(([key, val]) => {
        classes[type][key] = css(stepToRule(val, type));
      });
    }
  });
};

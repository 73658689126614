import React from "react";
import {Col, Text} from ".";
import css from "@emotion/css";
import colors from "./colors";

const Card = ({...rest}) => <Col bg="white" elevation={1} pa={5} {...rest} />;

const headerStyle = css({borderBottom: `1px solid ${colors.gray9}`});

export const CardWithHeader = ({header, children, ...rest}) => (
  <Card pa={null} {...rest}>
    <Col px={5} pt={3} pb={2} css={headerStyle}>
      <Text preset="semiBold" size={4} color="gray3">
        {header}
      </Text>
    </Col>
    <Col px={5} py={3}>
      {children}
    </Col>
  </Card>
);

export default Card;

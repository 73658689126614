import React from "react";
import {Col, Text, Row, Push, Grid} from "./ui";
import TransparentButton from "./ui/TransparentButton";
import IconButton from "./ui/IconButton";
import {IconClose, IconFoto, IconAudio, IconNotiz} from "./ui/icons";
import {TextareaWithLabel} from "./Input";
import css from "@emotion/css";
import colors from "./ui/colors";
import storeImage from "../lib/store-image";
import uuidv1 from "uuid/v1";
import {useFileSetter, useFileById} from "../models/files";
import useAudioRecorder from "./AudioRecorder";
import {blobToArrayBuffer, useAsObjectUrl} from "../lib/blob-utils";

const OverviewLane = ({count, singleLabel, pluralLabel, children, readOnly}) => {
  const getLabel = () => {
    if (count === 0) return `Keine ${pluralLabel}`;
    if (count === 1) return `1 ${singleLabel}`;
    return `${count} ${pluralLabel}`;
  };
  return (
    <Row align="center" sp={1} breaking>
      <Text color={count > 0 ? "gray3" : "gray5"} size={2}>
        {getLabel()}
      </Text>
      <Push />
      <Row sp={1} align="center">
        {!readOnly && children}
      </Row>
    </Row>
  );
};

const noteContainerStyle = css({
  border: `1px solid ${colors.gray8}`,
  borderRadius: "0.2rem",
});

const photoOverlayStyle = css({
  position: "absolute",
  bottom: "0.25rem",
  right: "0.25rem",
});

const Photo = ({id, onDelete, readOnly}) => {
  const mabyeFile = useFileById(id);
  const file = mabyeFile.value;
  const objectUrl = useAsObjectUrl(file);
  if (mabyeFile.isLoading) return null;
  if (!file) return null;

  return (
    <div css={{position: "relative"}}>
      <img
        src={objectUrl || file.url}
        width={file.width}
        height={file.height}
        alt=""
        css={{height: "5rem", width: "auto", display: "block"}}
      />
      {!readOnly && (
        <div css={photoOverlayStyle}>
          <TransparentButton onClick={() => onDelete(id)}>
            <IconClose color="white" />
          </TransparentButton>
        </div>
      )}
    </div>
  );
};

const Photos = ({photoIds, onDelete, onDeleteAll, readOnly}) => {
  if (!photoIds.length) return null;
  return (
    <Grid sp={3} css={noteContainerStyle} pa={3}>
      {photoIds.map(id => (
        <Photo key={id} id={id} onDelete={onDelete} readOnly={readOnly} />
      ))}
      <Col justify="end" css={{marginLeft: "auto"}}>
        <TransparentButton onClick={onDeleteAll}>alle löschen</TransparentButton>
      </Col>
    </Grid>
  );
};

const AudioPreview = ({audioId, onDelete, readOnly}) => {
  const mabyeFile = useFileById(audioId);
  const file = mabyeFile.value;
  const objectUrl = useAsObjectUrl(file);
  if (mabyeFile.isLoading) return null;
  if (!file) return null;

  return (
    <Row css={noteContainerStyle} px={3} py={2} sp={3}>
      <audio controls src={file.url || objectUrl} css={{display: "block", flex: "auto"}} />
      {!readOnly && <TransparentButton onClick={onDelete}>löschen</TransparentButton>}
    </Row>
  );
};

const EditNote = ({onSave, note, onDelete, readOnly}) => {
  const timeoutRef = React.useRef(null);
  const inputRef = React.useRef();
  const handleChange = e => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      onSave(inputRef.current.value);
      timeoutRef.current = null;
    }, 250);
  };
  React.useEffect(
    () => () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    []
  );
  return (
    <Col sp={4} css={noteContainerStyle} px={3} py={2}>
      <TextareaWithLabel
        defaultValue={note || ""}
        label="Text"
        name="note"
        readOnly={readOnly}
        onBlur={e => onSave(e.target.value)}
        onChange={handleChange}
        inputRef={inputRef}
      />
      {!readOnly && (
        <Col align="end">
          <TransparentButton onClick={onDelete}>löschen</TransparentButton>
        </Col>
      )}
    </Col>
  );
};

let nextId = 1;

const audioErrorToLabel = {
  short: "Knopf gedrückt halten zum Aufzeichnen.",
};

const defaultComment = {audioIds: [], photoIds: [], note: null};

const CommentSection = ({comment: rawComment, onChange, readOnly}) => {
  const comment = rawComment || defaultComment;
  const idRef = React.useRef((nextId += 1));
  const {audioIds, photoIds, note} = comment;

  const [writeNote, setWriteNote] = React.useState(false);

  const setFile = useFileSetter();

  const handleImage = e => {
    const file = (e.target.files || [])[0];
    if (file) {
      storeImage(file).then(({blob, width, height}) => {
        blobToArrayBuffer(blob).then(buff => {
          const id = uuidv1();
          setFile(id, {
            id,
            type: blob.type,
            blob: buff,
            bytesOnDevice: blob.size,
            width,
            height,
            url: null,
          });
          onChange({...comment, photoIds: [...photoIds, id]});
        });
      });
    }
  };
  const handler = React.useCallback(
    ({blob, size}) => {
      blobToArrayBuffer(blob).then(buff => {
        const id = uuidv1();
        setFile(id, {
          id,
          type: blob.type,
          blob: buff,
          bytesOnDevice: size,
          url: null,
        });
        onChange({...comment, audioIds: [...audioIds, id]});
      });
    },
    [onChange, setFile, comment, audioIds]
  );
  const {buttonProps, fallbackEl, pressed, error} = useAudioRecorder(handler);

  return (
    <Col sp={1}>
      <OverviewLane
        count={audioIds.length}
        singleLabel="Memo"
        pluralLabel="Memos"
        readOnly={readOnly}
      >
        {fallbackEl}
        <TransparentButton {...buttonProps}>Sprachmemo aufnehmen</TransparentButton>
        <IconButton icon={IconAudio} isRed={pressed} {...buttonProps} />
      </OverviewLane>
      {error && (
        <Col align="end">
          <Text preset="semiBold" size={2} color="red">
            {audioErrorToLabel[error] || error}
          </Text>
        </Col>
      )}
      {audioIds.map(audioId => (
        <AudioPreview
          key={audioId}
          audioId={audioId}
          readOnly={readOnly}
          onDelete={() => {
            setFile(audioId, null);
            onChange({...comment, audioIds: audioIds.filter(id => id !== audioId)});
          }}
        />
      ))}

      <OverviewLane
        count={photoIds.length}
        singleLabel="Foto"
        pluralLabel="Fotos"
        readOnly={readOnly}
      >
        <input
          type="file"
          accept="image/*"
          capture
          css={{display: "none"}}
          id={`img-upload-${idRef.current}`}
          onChange={handleImage}
        />
        <TransparentButton as="label" htmlFor={`img-upload-${idRef.current}`}>
          Foto schießen
        </TransparentButton>
        <IconButton icon={IconFoto} as="label" htmlFor={`img-upload-${idRef.current}`} />
      </OverviewLane>
      <Photos
        photoIds={photoIds}
        onDelete={id => {
          setFile(id, null);
          onChange({...comment, photoIds: photoIds.filter(pid => pid !== id)});
        }}
        onDeleteAll={() => {
          photoIds.forEach(id => setFile(id, null));
          onChange({...comment, photoIds: []});
        }}
        readOnly={readOnly}
      />

      <OverviewLane
        count={note !== null ? 1 : 0}
        singleLabel="Notiz"
        pluralLabel="Notizen"
        readOnly={readOnly}
      >
        {note === null && (
          <TransparentButton onClick={() => setWriteNote(!writeNote)}>
            Notiz schreiben
          </TransparentButton>
        )}
        <IconButton icon={IconNotiz} onClick={() => setWriteNote(!writeNote)} />
      </OverviewLane>
      {note !== null || writeNote ? (
        <EditNote
          note={note}
          onDelete={() => {
            onChange({...comment, note: null}).then(() => setWriteNote(false));
          }}
          onSave={note => {
            onChange({...comment, note: note || null}).then(() => {
              setWriteNote(false);
            });
          }}
          readOnly={readOnly}
        />
      ) : null}
    </Col>
  );
};

export default CommentSection;

import React from "react";

export const blobToArrayBuffer = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", e => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });

export const useAsObjectUrl = file => {
  const {blob, type} = file || {blob: null, type: null};
  return React.useMemo(() => blob && type && arrayBufferBlobToObjectURL(blob, type), [blob, type]);
};

export const arrayBufferBlobToObjectURL = (buffer, type) => {
  const blob = new Blob([buffer], {type: type});
  return URL.createObjectURL(blob);
};

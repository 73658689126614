import "typeface-source-sans-pro";
import "normalize.css/normalize.css";
import {injectGlobal} from "emotion";

injectGlobal`
html {
  font-family: 'Source Sans Pro', sans-serif;
  box-sizing: border-box;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  font-size: 1em;
  height: 100%;
}

/*
This rule determines what '1rem' refers to. On screens smaller than 30em it's always 1em = 16px
as defined in the html tag above.
On larger screens it is calculated as follows:
1em = 16px (if user has default browser settings)
0.88em = 14.08px
0.4vw = 0.4 * window.width / 100
At a window size of 30em (aka 480px) this results in:
0.88em + 0.4vw = 14.08px + 480px * 0.004 = 14.08px + 1.92px = 16px
For larger screen sizes this results in 1rem being greater than 16px.
*/

@media screen and (min-width: 30em) {
  html {
    font-size: calc(0.88em + 0.4vw);
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
  transition-duration: inherit;
  transition-timing-function: inherit;
  transition-property: none;
  background-size: inherit;
  background-position: inherit;
  background-repeat: inherit;
}

body {
  background-color: #fff;
  min-height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
`;

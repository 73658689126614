import React from "react";
import {Col, Text, Row} from "../../components/ui";
import DefaultLayout from "../../components/ui/DefaultLayout";
import TitleBar from "../../components/ui/TitleBar";
import TransparentButton from "../../components/ui/TransparentButton";
import {useTaskById, useTaskSetter} from "../../models/tasks";
import NotFoundScreen from "../NotFound";
import Card, {CardWithHeader} from "../../components/ui/Card";
import {format} from "date-fns";
import useStoToBrunnen from "../../lib/hooks/useStoToBrunnen";
import formatNumber from "../../lib/format-number";
import Button from "../../components/ui/Button";
import CommentSection from "../../components/Comment";
import {getMissingMeasurementCount} from "../../lib/task-utils";
import {IconArrowBack} from "../../components/ui/icons";
import {
  FormHeader,
  Field,
  Hint,
  useField,
  CommentSectionToggler,
  useAvgQ,
  PlausiHint,
  useFieldFocus,
} from "./shared";
import {InputWithLabel} from "../../components/Input";

const Zaehlerstand = ({task, setTask, lmDateStr, acronVal, lastVal, children}) => {
  const absSerializer = React.useMemo(
    () => ({
      get: v => v && v.type === "absolute" && v.value,
      set: v => (v !== null ? {value: v, type: "absolute", prev: lastVal} : null),
    }),
    [lastVal]
  );
  const [val, setVal, onReset] = useField({
    serializer: absSerializer,
    task,
    name: "zaehlerstand",
    minimumFractionDigits: 0,
    setTask,
  });

  const applyAcron = () => {
    if (acronVal) {
      onReset();
      setTask(task.id, {
        ...task,
        measurements: {
          ...task.measurements,
          zaehlerstand: {
            ...task.measurements.zaehlerstand,
            value: {value: acronVal, type: "relative", prev: lastVal},
          },
        },
      });
    }
  };

  const handleAbsCheckClick = () => {
    setTask(task.id, {
      ...task,
      measurements: {
        ...task.measurements,
        zaehlerstand: {
          ...task.measurements.zaehlerstand,
          value: absSerializer.set(val),
        },
      },
    });
  };

  const mVal = task.measurements.zaehlerstand.value;
  const valType = mVal && mVal.type;

  const {fieldProps} = useFieldFocus("zaehlerstand");

  return (
    <Card>
      <Col sp={3}>
        {acronVal && (
          <Row as="label" sp={1}>
            <input type="checkbox" onChange={applyAcron} checked={valType === "relative"} style={{flex: "none"}}/>
            <Text size={2} color="gray3">
              {acronVal}h seit letzter Messung von Acron übernehmen
            </Text>
          </Row>
        )}
        <Row as="label" sp={1}>
          <input type="checkbox" checked={valType === "absolute"} onChange={handleAbsCheckClick} style={{flex: "none"}}/>
          <Col sp={3} fillParent>
            <InputWithLabel
              type="number"
              label="Betriebsstundenzählerstand händisch eintragen"
              name="zaehlerstand"
              postfix="h"
              value={val || ""}
              onChange={task.done ? undefined : e => setVal(e.target.value)}
              readOnly={task.done}
              {...fieldProps}
            />
            {children}
            {lastVal && (
              <Hint label={`Letzter Zählerstand (${lmDateStr})`}>{formatNumber(lastVal)} h</Hint>
            )}
          </Col>
        </Row>
        <CommentSectionToggler task={task} setTask={setTask} name="zaehlerstand" />
      </Col>
    </Card>
  );
};

const DoVerifiedStrommessungScreen = ({task, navigate}) => {
  const missingMeasurements = getMissingMeasurementCount(task);
  const setTask = useTaskSetter();

  const missingMessage =
    missingMeasurements > 0
      ? `Messung oder Kommentar fehlt für ${
          missingMeasurements === 1 ? "ein Feld" : `${missingMeasurements} Felder`
        }`
      : null;

  const handleCommentChange = React.useCallback(
    comment => setTask(task.id, {...task, finalComment: comment}),
    [task, setTask]
  );

  const title = (
    <TitleBar
      title="Strommessung"
      left={
        <TransparentButton
          noTextOnMobile
          onDark
          to="/tasks/strom"
          leftIcon={<IconArrowBack color="white" />}
        >
          Zurück
        </TransparentButton>
      }
    />
  );

  const brunnen = useStoToBrunnen()[task.brunnenSTO];

  const lm = brunnen && brunnen.LAST_MESS;
  const td = brunnen && brunnen.TECH_DATEN;

  const {Q_MENGE, BETR_H, Q_AVG} = useAvgQ(brunnen) || {};

  if (!brunnen) {
    return (
      <DefaultLayout title={title}>
        <Text>Kein Zugriff zu diesem Brunnen</Text>
      </DefaultLayout>
    );
  }

  const bd = brunnen.BEWEG_DATEN;
  const lmDateStr = lm && format(lm.MESS_DAT, "DD.MM.YYYY");

  return (
    <DefaultLayout
      title={title}
      headerProps={{pb: 2}}
      header={
        <FormHeader brunnen={brunnen} measurementsLeft={missingMeasurements} isDone={task.done} />
      }
    >
      <Col sp={5}>
        <Col sp={3}>
          <Field
            name="stromaufnahme"
            label="Stromaufnahme"
            postfix="A"
            task={task}
            setTask={setTask}
          >
            <PlausiHint name="stromaufnahme" task={task} shown={val => td && val > td.I_A}>
              Der eingegebene Strom liegt über dem Nennstrom der Pumpe ({td && formatNumber(td.I_A, {minimumFractionDigits: 1})} A).
            </PlausiHint>
            <PlausiHint name="stromaufnahme" task={task} shown={val => val < 0}>
              Eine negative Stromaufnahme ist unplausibel.
            </PlausiHint>
            {lm && lm.I_AUFN && (
              <Hint label={`Letzter Wert (${lmDateStr})`}>{formatNumber(lm.I_AUFN)} A</Hint>
            )}
          </Field>
          <Zaehlerstand
            task={task}
            setTask={setTask}
            acronVal={bd && bd.BSTD_SEIT_LETZTER_ERG}
            lastVal={lm && lm.BETR_H_NEU}
            lmDateStr={lmDateStr}
          >
            <PlausiHint
              name="zaehlerstand"
              task={task}
              shown={() => td && Q_AVG && (Q_AVG < td.Q_N_M3_H * 0.5 || Q_AVG > td.Q_N_M3_H * 1.5)}
            >
              Der mittlere Volumenstrom (Qmittel={formatNumber(Q_AVG, {maximumFractionDigits: 1})} m³/h berechnet aus Wasserzählerstandsdifferenz={Q_MENGE} m³ und Betriebsstunden=
              {BETR_H}h) weicht deutlich von der Nennleistung
              der Pumpe ab (Qnenn= {td && td.Q_N_M3_H} m³/h).
            </PlausiHint>
          </Zaehlerstand>
          <CardWithHeader header="Abschließende Beurteilung">
            <CommentSection
              readOnly={task.done}
              comment={task.finalComment}
              onChange={handleCommentChange}
            />
          </CardWithHeader>
        </Col>
        <Col align="center" sp={4}>
          {missingMessage && (
            <Col px={3} py={1} bg="blue8" elevation={1} css={{borderRadius: "0.2rem"}}>
              <Text preset="bold" size={2} color="blue1">
                {missingMessage}
              </Text>
            </Col>
          )}
          <Button to="/tasks/strom">Zur Übersicht</Button>
        </Col>
      </Col>
    </DefaultLayout>
  );
};

const StromFormScreen = ({taskId, navigate, uri}) => {
  const maybeTask = useTaskById(taskId);
  if (maybeTask.isLoading) {
    return "loading";
  } else if (!maybeTask.value) {
    return <NotFoundScreen />;
  } else {
    const task = maybeTask.value;
    return <DoVerifiedStrommessungScreen task={task} navigate={navigate} />;
  }
};

export default StromFormScreen;

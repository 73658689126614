export const estimateStorage = () => {
  if ("storage" in navigator && "estimate" in navigator.storage) {
    return navigator.storage.estimate();
  }

  if (
    "webkitTemporaryStorage" in navigator &&
    "queryUsageAndQuota" in navigator.webkitTemporaryStorage
  ) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise((resolve, reject) => {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        (usage, quota) => resolve({usage: usage, quota: quota}),
        reject
      );
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.reject("Client does not support estimate");
};

import React from "react";
import {useBrunnenData} from "../../models/brunnen";

const useStoToBrunnen = () => {
  const maybeBrunnen = useBrunnenData();
  return React.useMemo(() => {
    const mapping = {};
    if (!maybeBrunnen.value) return {};
    maybeBrunnen.value.brunnen.forEach(b => {
      mapping[b.BRU_STO_KENNZ] = b;
    });
    return mapping;
  }, [maybeBrunnen.value]);
};

export default useStoToBrunnen;

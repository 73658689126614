import React from "react";
import {Link} from "@reach/router";

export const rawButtonStyles = {
  transitionProperty: "background-color, color, box-shadow, border-color, opacity",
  fontFamily: "inherit",
  fontSize: "inherit",
  textAlign: "center",
  width: "auto",
  display: "block",
  textDecoration: "none",
  border: "none",
  backgroundColor: "transparent",
  userSelect: "none",
  ":disabled": {opacity: 0.5},
  ":not(:disabled)": {cursor: "pointer"},
};

export const ButtonOrLinkComp = React.forwardRef((props, ref) => {
  if (props.as) {
    return <props.as ref={ref} {...props} />;
  } else if (props.to) {
    return <Link ref={ref} {...props} />;
  } else if (props.href) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a ref={ref} {...props} />;
  } else {
    return <button ref={ref} type="button" {...props} />;
  }
});

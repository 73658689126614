import React from "react";
import MainScreenLayout from "../../components/ui/MainScreenLayout";
import useStoToBrunnen from "../../lib/hooks/useStoToBrunnen";
import {useAllTasks, useTaskSetter} from "../../models/tasks";
import {Col, Text, Row, Push} from "../../components/ui";
import {getMissingMeasurementCount} from "../../lib/task-utils";
import {IconErg, IconStrom, IconClose, IconRws} from "../../components/ui/icons";
import Button from "../../components/ui/Button";
import css from "@emotion/css";
import colors from "../../components/ui/colors";
import {useStorageInfo} from "../../components/FileSyncer";
import {useNetworkStatus} from "../../lib/hooks/useNetworkStatus";
import {post} from "../../lib/requests";
import {useAllFiles} from "../../models/files";
import {format} from "date-fns";
import TransparentButton from "../../components/ui/TransparentButton";

const orderedTasks = [
  {type: "ERG", icon: IconErg, label: "Ergiebigkeitsmessung"},
  {type: "STROM", icon: IconStrom, label: "Strommessung"},
  {type: "RWS", icon: IconRws, label: "Ruhewasserstand"},
];

const BrunnenLane = ({sto, tasks, brunnen, selected, onToggleSelection, sentAt}) => (
  <Row
    sp={3}
    px={4}
    py={3}
    align="center"
    bg={selected ? "selectedBlue" : "white"}
    elevation={1}
    noShrink
  >
    <Col css={{minWidth: "30%"}}>
      <Text preset="semiBold" color={selected ? "blue2" : "green2"} size={4}>
        {brunnen ? brunnen.NAME : sto}
      </Text>
    </Col>
    <Col css={{minWidth: "30%"}} sp={1}>
      {orderedTasks
        .filter(({type}) => tasks[type])
        .map(({type, icon: IconComp, label}) => (
          <Row align="center" key={type}>
            <IconComp color={selected ? "blue2" : "green2"} size={4} />
            <Text color={selected ? "blue2" : "green2"} size={2}>
              {label}
            </Text>
          </Row>
        ))}
    </Col>
    <Push />
    {sentAt ? (
      <Col sp={0}>
        <Text size={0} color="gray5">
          Versandt
        </Text>
        <Text color="gray3" size={1}>
          {format(sentAt, "DD.MM.YYYY HH:mm")}
        </Text>
      </Col>
    ) : selected ? (
      <Button key="deselect" size="md" square onClick={() => onToggleSelection(sto)}>
        <IconClose color="white" />
      </Button>
    ) : (
      <Button key="select" size="md" onClick={() => onToggleSelection(sto)}>
        Auswählen
      </Button>
    )}
  </Row>
);

const badgeStyle = css({
  backgroundColor: "rgba(0,0,0,0.1)",
  height: "1.25rem",
  width: "1.25rem",
  lineHeight: 0.9,

  fontSize: `${14 / 16}rem`,
  color: colors.white,
  borderRadius: "1rem",
});

const Badge = ({children}) => (
  <Row align="center" justify="center" css={badgeStyle}>
    {children}
  </Row>
);

const taskToStoEntries = tasks => {
  const l = {};
  tasks.forEach(t => {
    if (!l[t.brunnenSTO]) l[t.brunnenSTO] = {};
    l[t.brunnenSTO][t.type] = t;
  });
  return Object.entries(l);
};

const ProtokollListeScreen = () => {
  const [selectedStos, setSelectedStos] = React.useState(new Set());
  const [state, setState] = React.useState("initial");

  const stoToBrunnen = useStoToBrunnen();
  const maybeTasks = useAllTasks();
  const maybeFiles = useAllFiles();
  const {isOnline} = useNetworkStatus();
  const setTask = useTaskSetter();
  const allTasks = React.useMemo(() => Object.values(maybeTasks.value || {}), [maybeTasks.value]);
  const [unsyncStoEntries, unsyncedFinishedTasks] = React.useMemo(() => {
    const unsyncedFinishedTasks = allTasks.filter(
      t => t && !t.done && getMissingMeasurementCount(t) === 0
    );
    return [
      taskToStoEntries(unsyncedFinishedTasks).filter(
        ([sto, tasks]) => (tasks.ERG && tasks.STROM) || tasks.RWS
      ),
      unsyncedFinishedTasks,
    ];
  }, [allTasks]);
  const [doneTasks, doneStoEntries] = React.useMemo(() => {
    const tasks = allTasks.filter(t => t && t.done);
    return [tasks, taskToStoEntries(tasks)];
  }, [allTasks]);

  const handleSelection = React.useCallback(sto => {
    setSelectedStos(prev => {
      if (prev.has(sto)) {
        return new Set([...prev].filter(id => id !== sto));
      } else {
        return new Set([...prev, sto]);
      }
    });
  }, []);

  const maybeStorageInfo = useStorageInfo();
  const hasUnsyncedFiles = maybeStorageInfo && maybeStorageInfo.unsyncedFiles.length > 0;

  const handleSend = () => {
    if (!maybeFiles.value) return;
    const files = maybeFiles.value;
    const convertComment = ({audioIds, photoIds, ...rest}) => ({
      audioUrls: audioIds.map(fId => (files[fId] || {url: null}).url),
      photoUrls: photoIds.map(fId => (files[fId] || {url: null}).url),
      ...rest,
    });
    const convertTask = t => {
      const newMeasures = Object.keys(t.measurements).reduce((m, key) => {
        const prev = t.measurements[key];
        m[key] = {...prev, comment: prev.comment && convertComment(prev.comment)};
        return m;
      }, {});
      return {
        ...t,
        measurements: newMeasures,
        finalComment: t.finalComment && convertComment(t.finalComment),
      };
    };
    const selectedTasks = unsyncedFinishedTasks.filter(t =>
      selectedStos.has(t.brunnenSTO.toString())
    );
    setState("loading");
    post("/send-tasks", {tasks: selectedTasks.map(convertTask)})
      .then(t =>
        Promise.all(selectedTasks.map(t => setTask(t.id, {...t, done: true, sentAt: new Date()})))
      )
      .then(
        () => {
          setState("success");
        },
        e => {
          console.log(e);
          setState("error");
        }
      )
      .then(() => setSelectedStos(new Set()));
  };

  const handleClearArchive = () => {
    return Promise.all(doneTasks.map(t => setTask(t.id, null)));
  };

  const getLabel = () => {
    if (!isOnline) return "Kein Internet";
    if (state === "error") return "Fehler beim Senden";
    if (state === "success") return "Daten gesendet";
    return "Messdaten an Server senden";
  };

  return (
    <MainScreenLayout>
      <Col sp={7} fillParent>
        <Col sp={3}>
          <Text preset="bold" color="gray3" align="center" size={2}>
            Abgeschlossene Messungen
          </Text>
          {unsyncStoEntries.length > 0 ? (
            <Col sp={0}>
              {unsyncStoEntries.map(([sto, tasks]) => (
                <BrunnenLane
                  key={sto}
                  sto={sto}
                  tasks={tasks}
                  brunnen={stoToBrunnen[sto]}
                  selected={selectedStos.has(sto)}
                  onToggleSelection={handleSelection}
                />
              ))}
            </Col>
          ) : (
            <Col justify="center" sp={2} align="center">
              <Text color="gray3" align="center" size={2}>
                {state === "success"
                  ? "Alle Messungen eingesandt"
                  : "Noch keine fertigen Messungen"}
              </Text>
            </Col>
          )}
          <Col align="center" sp={3}>
            <Button
              disabled={
                !isOnline || state === "loading" || hasUnsyncedFiles || selectedStos.size === 0
              }
              onClick={handleSend}
            >
              <Row align="center" sp={1}>
                <Badge>{selectedStos.size}</Badge>
                <Text preset="button">{getLabel()}</Text>
              </Row>
            </Button>
            {hasUnsyncedFiles && <Text>Bitte zuerst die Dateien unten hochladen.</Text>}
          </Col>
        </Col>
        {doneTasks.length > 0 && (
          <Col sp={3}>
            <Text preset="bold" color="gray3" align="center" size={2}>
              Archiv
            </Text>
            <Col sp={0}>
              {doneStoEntries.map(([sto, tasks]) => (
                <BrunnenLane
                  key={sto}
                  sto={sto}
                  tasks={tasks}
                  brunnen={stoToBrunnen[sto]}
                  sentAt={Math.max(
                    (tasks.ERG && tasks.ERG.sentAt) || 0,
                    (tasks.STROM && tasks.STROM.sentAt) || 0,
                    (tasks.RWS && tasks.RWS.sentAt) || 0
                  )}
                />
              ))}
            </Col>
            <Col align="end" px={4}>
              <TransparentButton onClick={handleClearArchive} textProps={{size: 1, color: "gray3"}}>
                Archiv leeren
              </TransparentButton>
            </Col>
          </Col>
        )}
      </Col>
    </MainScreenLayout>
  );
};

export default ProtokollListeScreen;

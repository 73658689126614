import React from "react";
import {Col, Text} from "../../components/ui";
import DefaultLayout from "../../components/ui/DefaultLayout";
import Button from "../../components/ui/Button";

const NotFoundScreen = ({title = "Diese Seite existiert nicht"}) => (
  <DefaultLayout
    header={
      <Text preset="bold" size={6} color="blue2">
        404
      </Text>
    }
  >
    <Col sp={3} align="center">
      <Text align="center" color="blue2">
        {title}
      </Text>
      <Button to="/">Zum Hauptmenü</Button>
    </Col>
  </DefaultLayout>
);
export default NotFoundScreen;

const colors = {
  gray1: "#333333",
  gray2: "#4F4F4F",
  gray3: "#617C91",
  gray5: "#95A1A9",
  gray7: "#C4C4C4",
  gray8: "#D2D2D2",
  gray9: "#F1F4F9",

  selectedBlue: "#E3E8EF",

  white: "#FFFFFF",

  green1: "#729c16",
  green2: "#8AB925",
  green2_50p: "rgba(138, 185, 37, 0.5)",
  blue1: "#00508B",
  blue2: "#0072C6",
  blue2_50p: "rgba(0, 114, 198, 0.5)",
  blue8: "#d1ecff",
  red: "#FF4F17",

  warn8: "#ffcc8d",
  warn2: "#8c530c",
  warn1: "#613704",
};

export default colors;

const formatNumber = (num, opts = {minimumFractionDigits: 0}) => {
  if (typeof num !== "number") return num;
  if (num.toLocaleString) {
    return num.toLocaleString(undefined, {maximumFractionDigits: 2, useGrouping: false, ...opts});
  } else {
    return num.toFixed(2);
  }
};

export default formatNumber;

import uuidv1 from "uuid/v1";

// const tasks = {
//   [id]: {
//     id: uuid,
//     type: "ERG",
//     brunnenSTO: 12323,
//     measurements: {
//       zaehlerstand: {
//         value: 27485,
//         comment: {
//           audioIds: [132],
//           photoIds: [],
//           note: "some note",
//         },
//       },
//       momVolumen:
//       druck:
//       wasserstand:
//       tempPumpe:
//       drehzahlPumpe:
//     },
//     questions: {
//       sauber: null,
//       wasserImSchacht: false,
//       schwimmschalterFunktioniert: true,
//       deckelkontaktFunktioniert: null,
//     },
//     finalComment: null,
//     done: false,
//   },
// };

import {setupStore} from "../lib/idbStore";

const {StoreProvider, useSet, useGet, useGetAll} = setupStore("tasks-v1");

export const TaskContextProvider = StoreProvider;
export const useTaskSetter = useSet;
export const useTaskById = useGet;
export const useAllTasks = useGetAll;

const werkWithTempPump = new Set(["Beelitzhof", "Tiefwerder"]);

export const newErgTask = (sto, brunnen) => {
  const werk = brunnen && brunnen.WERK;
  const pumpe = brunnen && brunnen.TECH_DATEN && brunnen.TECH_DATEN.PUMPE;
  const id = uuidv1();
  const shuoldMeasureTemp = werkWithTempPump.has(werk);
  return {
    id,
    type: "ERG",
    brunnenSTO: sto,
    finalComment: null,
    done: false,
    sentAt: null,
    lastSaved: null,
    measurements: {
      zaehlerstand: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      momVolumen: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      druck: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      wasserstand: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      ...(shuoldMeasureTemp && {
        tempPumpe: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      }),
      ...(pumpe &&
        pumpe.FU === 1 && {
          drehzahlPumpe: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
        }),
    },
    questions: {
      sauber: null,
      wasserImSchacht: null,
      schwimmschalterFunktioniert: null,
      deckelkontaktFunktioniert: null,
    },
  };
};

export const newStromTask = sto => {
  const id = uuidv1();
  return {
    id,
    type: "STROM",
    brunnenSTO: sto,
    finalComment: null,
    done: false,
    sentAt: null,
    lastSaved: null,
    measurements: {
      stromaufnahme: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
      zaehlerstand: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
    },
  };
};

export const newRwsTask = sto => {
  const id = uuidv1();
  return {
    id,
    type: "RWS",
    brunnenSTO: sto,
    finalComment: null,
    done: false,
    sentAt: null,
    lastSaved: null,
    measurements: {
      ruhewasserstand: {value: null, comment: null, firstUpdated: null, lastUpdated: null},
    },
  };
};

import React from "react";
import MainScreenLayout from "../../components/ui/MainScreenLayout";
import {IconErg, IconRws} from "../../components/ui/icons";
import TaskList from "../../components/TaskList";

const typeToPath = {
  ERG: "erg",
  RWS: "rws",
};

const Icon = ({type, ...rest}) => (type === "RWS" ? <IconRws {...rest} /> : <IconErg {...rest} />);

const ErgListeScreen = () => (
  <MainScreenLayout>
    <TaskList
      taskType={["ERG", "RWS"]}
      icon={Icon}
      taskToUrl={t => `/tasks/${typeToPath[t.type]}/${t.id}`}
    />
  </MainScreenLayout>
);

export default ErgListeScreen;

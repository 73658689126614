import React from "react";
import {useLoadBrunnenDataFromServer, useBrunnenData} from "../models/brunnen";
import {useNetworkStatus} from "../lib/hooks/useNetworkStatus";
import css from "@emotion/css";
import {Col, Row, Text} from "./ui";
import Button from "./ui/Button";

const setToMidnight = date => {
  date.setHours(0, 0, 0, 0);
  return date;
};

const styles = {
  outer: css({position: "absolute", top: "3.5rem", left: 0, right: 0}),
  pill: css({borderRadius: "0.5rem"}),
};

const getDayDiff = dataInfo => {
  return (
    dataInfo &&
    Math.ceil((setToMidnight(new Date()) - new Date(dataInfo.dataGeneratedAt)) / (1000 * 3600 * 24))
  );
};

export const isDataOld = dataInfo => {
  return dataInfo && new Date(dataInfo.dataGeneratedAt) < setToMidnight(new Date());
};

const isXMinsAgo = (date, x) => {
  return new Date() - date > x * 60 * 1000;
};

const DataFreshness = () => {
  const [state, setState] = React.useState("initial");
  const [lastTried, setLastTried] = React.useState(null);
  const loadBrunnen = useLoadBrunnenDataFromServer();
  const maybeBrunnen = useBrunnenData();
  const {isOnline} = useNetworkStatus();

  const dataInfo = maybeBrunnen.value && maybeBrunnen.value.info;
  const isOld = isDataOld(dataInfo);
  const dayDiff = getDayDiff(dataInfo);

  const getBrunnenData = React.useCallback(() => {
    setState("loading");
    loadBrunnen().then(
      () => {
        setState("success");
      },
      e => {
        console.log(e);
        setState("error");
      }
    );
  }, [loadBrunnen]);

  React.useEffect(() => {
    if (isOld && isOnline && (!lastTried || isXMinsAgo(lastTried, 5))) {
      setLastTried(new Date());
      getBrunnenData();
    }
  }, [lastTried, isOnline, isOld, getBrunnenData]);

  const getLabel = () => {
    if (!isOnline) return "Kein Internet";
    if (state === "error") return "Fehler beim Laden";
    if (state === "success") return "Daten geladen";
    return "Laden";
  };

  if (!isOld) return null;

  return (
    <Col css={styles.outer} align="center">
      <Row css={styles.pill} bg="warn8" px={3} py={0} elevation={2} align="center" sp={2}>
        <Text size={1} lineHeight="tight" color="warn2" css={{position: "relative", top: 2}}>
          Brunnendaten sind {dayDiff} Tag{dayDiff === 1 ? "" : "e"} alt.
        </Text>
        <Button
          size="sm"
          color="warn"
          disabled={state === "loading" || !isOnline}
          onClick={getBrunnenData}
        >
          {getLabel()}
        </Button>
      </Row>
    </Col>
  );
};

export default DataFreshness;

const measurementDone = ({value, comment}) => {
  if (value !== null) return true;
  if (comment) {
    if (comment.note !== null || comment.photoIds.length || comment.audioIds.length) return true;
  }
  return false;
};

export const getMissingMeasurementCount = task => {
  return Object.values(task.measurements).filter(t => !measurementDone(t)).length;
};

export const getDoneMeasurmentCount = task => {
  return Object.values(task.measurements).filter(measurementDone).length;
};

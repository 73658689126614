import React from "react";
import {Col, Text, Row, Push} from "./ui";
import css from "@emotion/css";
import colors from "./ui/colors";
import {rawButtonStyles} from "./ui/buttonHelpers";
import styled from "@emotion/styled";
import mq from "./ui/media-queries";

const labelStyle = css({
  display: "block",
  color: colors.gray3,
  transitionProperty: "color",
  [mq.mobile]: {textAlign: "center"},
});
const Label = props => <Text size={3} as="label" css={labelStyle} {...props} />;

const radioOptions = [{label: "ja", value: true}, {label: "nein", value: false}];

const styles = {
  withErrors: css({
    borderColor: colors.red,
  }),
  active: css({
    backgroundColor: colors.blue2,
    color: colors.white,
    borderColor: colors.blue2,
    ":not(:disabled):hover": {
      backgroundColor: colors.blue1,
      borderColor: colors.blue1,
    },
  }),
};

const Button = styled("button")(rawButtonStyles, {
  position: "relative",
  boxShadow: `0 0 0 0.2rem transparent, 0 0.1rem 0.1rem rgba(0,0,0,0.1), 0 0.1rem 0.5rem rgba(0,0,0,0.05)`,
  padding: "1rem 2rem",
  color: colors.blue2,
  backgroundColor: colors.gray9,
  border: `1px solid ${colors.gray8}`,
  borderRightStyle: "none",
  transitionProperty: "background-color, box-shadow, border-color",
  ":last-of-type": {
    borderRadius: "0 0.2rem 0.2rem 0",
    borderRightStyle: "solid",
  },
  ":first-of-type": {
    borderRadius: "0.2rem 0 0 0.2rem",
  },
  ":first-of-type:last-of-type": {
    borderRadius: "0.2rem",
  },
  ":not(:disabled):hover": {
    backgroundColor: colors.gray8,
    borderColor: colors.gray7,
  },
  ":focus": {
    outline: "none",
    zIndex: 1,
    boxShadow: `0 0 0 0.2rem ${
      colors.blue2_50p
    }, 0 0.1rem 0.1rem rgba(0,0,0,0.1), 0 0.1rem 0.5rem rgba(0,0,0,0.05)`,
  },
});

const SingleButton = ({hasErrors, isActive, ...rest}) => (
  <Button
    type="button"
    css={[isActive && styles.active, hasErrors && styles.withErrors]}
    {...rest}
  />
);

const ButtonGroup = ({hasErrors, value, name, onChange, onFocus, onBlur, disabled, options}) => (
  <Row>
    {options.map(({label, value: buttonValue}) => (
      <SingleButton
        disabled={disabled}
        key={buttonValue}
        value={buttonValue}
        onClick={() => onChange({target: {type: "custom", value: buttonValue, name}})}
        onFocus={onFocus}
        onBlur={onBlur && onBlur(name)}
        isActive={buttonValue === value}
        hasErrors={hasErrors}
      >
        {label}
      </SingleButton>
    ))}
  </Row>
);

export const RadioButtonWithLabel = React.forwardRef(
  ({name, label = name, errors = [], value, onChange, onFocus, onBlur, disabled}, ref) => {
    const hasErrors = errors.length > 0;

    return (
      <Col sp={2} fillParent>
        <Row align="center" sp={3} breaking={2}>
          <Label>{label}</Label>
          <Push />
          <ButtonGroup
            disabled={disabled}
            name={name}
            hasErrors={hasErrors}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            options={radioOptions}
          />
        </Row>
        {hasErrors && (
          <Row sp={1}>
            {errors.map((e, i) => (
              <Text size={1} color="red" key={i}>
                {e}
              </Text>
            ))}
          </Row>
        )}
      </Col>
    );
  }
);

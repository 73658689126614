import React from "react";
import {Router, Redirect} from "@reach/router";
import {useBrunnenData, BrunnenContextProvider} from "./models/brunnen";
import LoginScreen from "./screens/Login";
import DefaultLayout from "./components/ui/DefaultLayout";
import PickBrunnenScreen from "./screens/Tasks/PickBrunnen";
import {TaskContextProvider} from "./models/tasks";
import ErgFormScreen from "./screens/Forms/Erg";
import NotFoundScreen from "./screens/NotFound";
import {FileContextProvider} from "./models/files";
import ErgListeScreen from "./screens/Tasks/ErgListe";
import StromListeScreen from "./screens/Tasks/StromListe";
import ProtokollListeScreen from "./screens/Tasks/ProtokollListe";
import StromFormScreen from "./screens/Forms/Strom";
import DataFreshness from "./components/DataFreshness";
import RwsFormScreen from "./screens/Forms/Rws";

const Main = ({children, navigate, location}) => {
  const maybeBrunnenData = useBrunnenData();

  const {pathname, search} = location;
  React.useEffect(() => {
    if (!maybeBrunnenData.isLoading && !maybeBrunnenData.value) {
      navigate(`/login`, {
        replace: true,
        state: {afterLoginLocation: {pathname, search}},
      });
    }
  }, [maybeBrunnenData, pathname, search, navigate]);

  if (maybeBrunnenData.isLoading) {
    return <DefaultLayout>loading</DefaultLayout>;
  } else if (maybeBrunnenData.value === null) {
    return <DefaultLayout>redirecting</DefaultLayout>;
  } else {
    return (
      <TaskContextProvider>
        <FileContextProvider>
          <DataFreshness />
          {children}
        </FileContextProvider>
      </TaskContextProvider>
    );
  }
};

const App = () => (
  <BrunnenContextProvider>
    <Router>
      <LoginScreen path="/login" />
      <Main path="/">
        <PickBrunnenScreen path="/tasks/pick" />
        <ErgListeScreen path="/tasks/brunnen" />
        <StromListeScreen path="/tasks/strom" />
        <ProtokollListeScreen path="/tasks/send" />

        <ErgFormScreen path="/tasks/erg/:taskId" />
        <RwsFormScreen path="/tasks/rws/:taskId" />
        <StromFormScreen path="/tasks/strom/:taskId" />

        <Redirect from="/" to="/tasks/pick" noThrow />
        <NotFoundScreen default />
      </Main>
    </Router>
  </BrunnenContextProvider>
);

export default App;

import React from "react";
import {Col, Text} from "../../components/ui";
import DefaultLayout from "../../components/ui/DefaultLayout";
import TitleBar from "../../components/ui/TitleBar";
import TransparentButton from "../../components/ui/TransparentButton";
import {useTaskById, useTaskSetter} from "../../models/tasks";
import NotFoundScreen from "../NotFound";
import {format} from "date-fns";
import useStoToBrunnen from "../../lib/hooks/useStoToBrunnen";
import formatNumber from "../../lib/format-number";
import {getEinbautiefe} from "../../lib/brunnen-utils";
import Button from "../../components/ui/Button";
import {getMissingMeasurementCount} from "../../lib/task-utils";
import {IconArrowBack} from "../../components/ui/icons";
import {FormHeader, Field, Hint, PlausiHint} from "./shared";

const DoVerifiedRwsMessungScreen = ({task, navigate}) => {
  const missingMeasurements = getMissingMeasurementCount(task);
  const setTask = useTaskSetter();

  const title = (
    <TitleBar
      title="Ruhewasserstandsmessung"
      left={
        <TransparentButton
          noTextOnMobile
          onDark
          to="/tasks/brunnen"
          leftIcon={<IconArrowBack color="white" />}
        >
          Zurück
        </TransparentButton>
      }
    />
  );

  const brunnen = useStoToBrunnen()[task.brunnenSTO];
  const lm = brunnen && brunnen.RWS;

  if (!brunnen) {
    return (
      <DefaultLayout title={title}>
        <Text>Kein Zugriff zu diesem Brunnen</Text>
      </DefaultLayout>
    );
  }

  const lmDateStr = lm && format(lm.DATUM, "DD.MM.YYYY");
  const depth = getEinbautiefe(brunnen);

  const missingMessage =
    missingMeasurements > 0
      ? `Messung oder Kommentar fehlt für ${
          missingMeasurements === 1 ? "ein Feld" : `${missingMeasurements} Felder`
        }`
      : null;

  return (
    <DefaultLayout
      title={title}
      headerProps={{pb: 2}}
      header={
        <FormHeader
          brunnen={brunnen}
          measurementsLeft={missingMeasurements}
          isDone={task.done}
          isRws
        />
      }
    >
      <Col sp={5}>
        <Col sp={3}>
          <Field
            name="ruhewasserstand"
            label="Ruhewasserstand"
            postfix="m"
            task={task}
            setTask={setTask}
            minimumFractionDigits={2}
          >
            <PlausiHint
              name="ruhewasserstand"
              task={task}
              shown={val => val < 2 || (depth && val > depth)}
            >
              Der eingegebene Wasserstand weicht vom Normalbereich ab.
            </PlausiHint>
            {depth && <Hint label="Einbautiefe Pumpe">{formatNumber(depth)} m</Hint>}
            {lm && <Hint label={`Letzter Wert (${lmDateStr})`}>{formatNumber(lm.RWS)} m</Hint>}
          </Field>
        </Col>

        <Col align="center" sp={4}>
          {missingMessage && (
            <Col px={3} py={1} bg="blue8" elevation={1} css={{borderRadius: "0.2rem"}}>
              <Text preset="bold" size={2} color="blue1">
                {missingMessage}
              </Text>
            </Col>
          )}
          <Button to="/tasks/brunnen">Zur Übersicht</Button>
        </Col>
      </Col>
    </DefaultLayout>
  );
};

const RwsFormScreen = ({taskId, navigate, uri}) => {
  const maybeTask = useTaskById(taskId);
  if (maybeTask.isLoading) {
    return "loading";
  } else if (!maybeTask.value) {
    return <NotFoundScreen />;
  } else {
    const task = maybeTask.value;
    return <DoVerifiedRwsMessungScreen task={task} navigate={navigate} />;
  }
};

export default RwsFormScreen;

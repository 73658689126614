import React from "react";
import {
  SliderInput,
  SliderTrack,
  SliderTrackHighlight,
  SliderHandle,
  SliderMarker,
} from "@reach/slider";
import "@reach/slider/styles.css";
import {Global, css} from "@emotion/core";
import colors from "./ui/colors";
import {Col, Text} from "./ui";

const globalStyles = css`
  .bwb-slider[data-reach-slider-input][data-orientation="horizontal"] {
    height: 8px;
    padding: 0;
    width: 100%;
  }
  .bwb-slider[data-reach-slider-input]:focus {
    outline: none;
  }
  .bwb-slider [data-reach-slider-track] {
    border-radius: 20px;
    border: 1px solid ${colors.gray5};
    background: none;
  }
  .bwb-slider [data-reach-slider-track-highlight] {
    background: ${colors.green2};
  }
  .bwb-slider [data-reach-slider-marker] {
    border: 1px solid ${colors.gray5};
    background: ${colors.gray9};
    height: 10px;
    width: 10px;
    border-radius: 50%;
  }
  .bwb-slider [data-reach-slider-marker][data-state="under-value"] {
    background: ${colors.green1};
    z-index: 1;
    border: 1px solid ${colors.white};
  }
  .bwb-slider [data-reach-slider-handle] {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid ${colors.green1};
  }
  .bwb-slider [data-reach-slider-handle]:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1);
  }
`;

const styles = {
  mark: {
    base: css({
      position: "absolute",
      bottom: "100%",
      width: "1.5rem",
      left: 4,
      marginLeft: "-0.75rem",
    }),
  },
};

const Mark = ({children, active}) => (
  <Col align="center" css={styles.mark.base} pb={1}>
    <Text size={1} color={active ? "green1" : "gray3"}>
      {children}
    </Text>
  </Col>
);

const Slider = ({value, onChange, options}) => (
  <>
    <Global styles={globalStyles} />
    <SliderInput
      min={0}
      max={options.length - 1}
      value={options.findIndex(o => o.value === value || 0)}
      step={1}
      onChange={val => onChange(options[val].value)}
      className="bwb-slider"
    >
      <SliderTrack>
        {options.map((o, i) => (
          <SliderMarker value={i} key={i}>
            <Mark active={value === o.value}>{o.label}</Mark>
          </SliderMarker>
        ))}
        <SliderTrackHighlight />
        <SliderHandle />
      </SliderTrack>
    </SliderInput>
  </>
);

export default Slider;

import {errorToString} from "./errorToString";

const processResponse = res => {
  return res.json().then(data => {
    if (res.status >= 400) return Promise.reject(new Error(data.error || errorToString(data)));
    return data;
  });
};

export const post = (path, data) => {
  const url = `${process.env.REACT_APP_API_HOST}${path}`;
  return fetch(url, {
    method: "Post",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then(processResponse);
};

export const get = path => {
  const url = `${process.env.REACT_APP_API_HOST}${path}`;
  return fetch(url, {
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  }).then(processResponse);
};

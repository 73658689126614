import {FormikProvider, useFormik, useField, useFormikContext} from "formik";
import * as React from "react";
import spected from "spected";
import {InputWithLabel} from "./Input";
import {errorToString} from "../lib/errorToString";

export const ShowError = ({form: {errors}}) =>
  errors.$global ? <div style={{marginBottom: 5}}>{errors.$global}</div> : null;

export const Form = ({initialValues, rules, style, className, onSubmit, children}) => {
  const handleSubmit = React.useCallback(
    (values, actions) => {
      const retVal = onSubmit(values, actions);
      actions.setStatus(undefined);
      if (retVal) {
        retVal.then(
          () => {
            actions.setSubmitting(false);
            actions.setStatus("done");
          },
          e => {
            actions.setSubmitting(false);
            actions.setErrors({$global: e ? errorToString(e) : "Oh no! Something bad happened!"});
          }
        );
      } else {
        actions.setSubmitting(false);
      }
    },
    [onSubmit]
  );

  const validate = React.useCallback(
    values => {
      if (!rules) return undefined;
      const res = spected(typeof rules === "function" ? rules(values) : rules, values);
      const errors = Object.keys(res).reduce((m, key) => {
        if (res[key] !== true) m[key] = res[key];
        return m;
      }, {});
      return errors;
    },
    [rules]
  );

  const formik = useFormik({initialValues, validate, onSubmit: handleSubmit});

  return (
    <FormikProvider value={formik}>
      <form
        className={className}
        style={{display: "flex", flexDirection: "column", ...style}}
        onSubmit={formik.handleSubmit}
      >
        <ShowError form={formik} />
        {children}
      </form>
    </FormikProvider>
  );
};

const useFocusOnError = ({fieldRef, name}) => {
  const formik = useFormikContext();
  const prevSubmitCountRef = React.useRef(formik.submitCount);
  const firstErrorKey = Object.keys(formik.errors)[0];
  React.useEffect(() => {
    if (prevSubmitCountRef.current !== formik.submitCount && !formik.isValid) {
      if (fieldRef.current && firstErrorKey === name) fieldRef.current.focus();
    }
    prevSubmitCountRef.current = formik.submitCount;
  }, [formik.submitCount, formik.isValid, firstErrorKey, name, fieldRef]);
};

const Input = ({as: Comp = InputWithLabel, name, ...props}) => {
  const fieldRef = React.useRef();
  const [field, meta] = useField(name);
  useFocusOnError({fieldRef, name});
  const errors = (meta.touch && meta.error) || [];
  return <Comp ref={fieldRef} name={name} errors={errors} {...props} {...field} />;
};

Form.Input = Input;

export const rules = {
  isRequired: [v => v !== null && v !== undefined && v !== "", "Pflichtfeld"],
  isEmail: [v => /\S+@\S+\.\S+/.test(v), "Keine Email"],
  minLength: num => [v => v.length >= num, `needs to be at least ${num} characters long`],
};

import React from "react";
import {setupStore} from "../lib/idbStore";
import {get} from "../lib/requests";

const {StoreProvider, useSet, useGet} = setupStore("auth");

export const BrunnenContextProvider = StoreProvider;
export const useBrunnenData = () => useGet("data");
export const useClearBrunnenData = () => {
  const setData = useSet();
  return React.useCallback(() => {
    setData("data", null);
  }, [setData]);
};

export const useLoadBrunnenDataFromServer = () => {
  const setData = useSet();
  return React.useCallback(() => {
    return get("/brunnen-data").then(res => {
      if (!res.error) return setData("data", res);
    });
  }, [setData]);
};

import React from "react";
import {Col, Text, Push, Row} from "./ui";
import TransparentButton from "./ui/TransparentButton";
import Button from "./ui/Button";
import {useAllTasks, useTaskSetter} from "../models/tasks";
import useStoToBrunnen from "../lib/hooks/useStoToBrunnen";
import {getMissingMeasurementCount, getDoneMeasurmentCount} from "../lib/task-utils";

const getStatus = task => {
  const doneMeasurements = getDoneMeasurmentCount(task);
  if (doneMeasurements === 0) return "planned";
  if (doneMeasurements < Object.keys(task.measurements).length) return "inProgress";
  return "finished";
};

const buttonByStatus = {
  finished: "Bearbeiten",
  inProgress: "Fortsetzen",
  planned: "Starten",
};

const TaskLane = ({
  task,
  stoToBrunnen,
  isRemoving,
  status,
  icon: IconComp,
  taskToUrl,
  addPrefix,
}) => {
  const measureKeys = Object.keys(task.measurements);
  const doneMeasurementCount = measureKeys.length - getMissingMeasurementCount(task);
  const brunnen = stoToBrunnen[task.brunnenSTO];
  const setTask = useTaskSetter();

  const onDelete = () => setTask(task.id, null);
  return (
    <Row sp={3} px={4} py={3} align="center" bg="white" elevation={1}>
      <Col css={{minWidth: "30%"}}>
        <Text preset="semiBold" color={status === "finished" ? "green2" : "gray3"} size={4}>
          {brunnen ? brunnen.NAME : task.brunnenSTO}
        </Text>
      </Col>
      <Row css={{minWidth: "30%"}} sp={1} align="center">
        <IconComp color={status === "finished" ? "green2" : "blue2"} size={4} type={task.type} />
        <Text color={status === "finished" ? "green2" : "blue2"} size={2}>
          <b>
            {doneMeasurementCount}/{measureKeys.length}
          </b>{" "}
          Messungen abgeschlossen
        </Text>
      </Row>
      <Push />

      {isRemoving ? (
        <TransparentButton key="remove" onClick={onDelete} textProps={{color: "red"}}>
          Entfernen
        </TransparentButton>
      ) : (
        <Button size="md" to={taskToUrl(task)}>
          {`${addPrefix ? `${task.type} ` : ""}${buttonByStatus[status]}`}
        </Button>
      )}
    </Row>
  );
};

const statusToLabel = {
  planned: "Geplant",
  inProgress: "In Arbeit",
  finished: "Abgeschlossen und nicht versendet",
};

const ListTasks = ({tasks, stoToBrunnen, isRemoving, status, icon, taskToUrl, addPrefix}) => {
  tasks.sort((t1, t2) => {
    const b1 = stoToBrunnen[t1.brunnenSTO];
    const b2 = stoToBrunnen[t2.brunnenSTO];
    return b1 && b2 && b1.NAME > b2.NAME ? 1 : -1;
  });
  return (
    <Col sp={3}>
      <Text preset="bold" color="gray3" align="center" size={2}>
        {statusToLabel[status]}
      </Text>
      <Col sp={0}>
        {tasks.map(task => (
          <TaskLane
            key={task.id}
            task={task}
            stoToBrunnen={stoToBrunnen}
            isRemoving={isRemoving}
            status={status}
            icon={icon}
            taskToUrl={taskToUrl}
            addPrefix={addPrefix}
          />
        ))}
      </Col>
    </Col>
  );
};

const ordererdStatus = ["finished", "inProgress", "planned"];

const TaskList = ({taskType, icon: IconComp, taskToUrl}) => {
  const [isRemoving, setIsRemoving] = React.useState(false);
  const maybeTasks = useAllTasks();
  const hasMultipleTypes = Array.isArray(taskType);
  const testType = hasMultipleTypes ? type => taskType.includes(type) : type => taskType === type;
  const typeTasks = Object.values(maybeTasks.value || []).filter(t => testType(t.type));

  const unsyncedTasks = typeTasks.filter(t => t && !t.done);

  const stoToBrunnen = useStoToBrunnen();

  const groups = {};
  unsyncedTasks.forEach(t => {
    const status = getStatus(t);
    if (!groups[status]) groups[status] = [];
    groups[status].push(t);
  });

  return (
    <Col fillParent sp={5}>
      {unsyncedTasks.length > 0 && (
        <Col align="end" px={4} css={{marginTop: "-2rem"}}>
          <TransparentButton
            onClick={() => setIsRemoving(!isRemoving)}
            textProps={{size: 1, color: isRemoving ? "blue2" : "gray3"}}
          >
            {isRemoving ? "beenden" : "Aufgaben entfernen"}
          </TransparentButton>
        </Col>
      )}
      {unsyncedTasks.length > 0 ? (
        <Col sp={6}>
          {ordererdStatus
            .filter(s => groups[s])
            .map(s => (
              <ListTasks
                key={s}
                tasks={groups[s]}
                status={s}
                stoToBrunnen={stoToBrunnen}
                isRemoving={isRemoving}
                icon={IconComp}
                taskToUrl={taskToUrl}
                addPrefix={hasMultipleTypes}
              />
            ))}
        </Col>
      ) : (
        <Col fillParent justify="center" sp={2} align="center">
          <IconComp color="gray3" size={6} />
          <Text color="gray3" align="center">
            Noch keine Brunnen ausgewählt
          </Text>
        </Col>
      )}
    </Col>
  );
};

export default TaskList;

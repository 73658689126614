import React from "react";
import {useLoadBrunnenDataFromServer, useBrunnenData} from "../../models/brunnen";
import {Push, Col, Text} from "../../components/ui";
import Card from "../../components/ui/Card";
import DefaultLayout from "../../components/ui/DefaultLayout";
import Button from "../../components/ui/Button";
import {Form, rules} from "../../components/Form";
import {post} from "../../lib/requests";
import {errorToString} from "../../lib/errorToString";

const Layout = ({children}) => (
  <DefaultLayout
    header={
      <img
        src={require("./bwb-logo-farbig.svg")}
        width="356"
        height="110"
        css={{width: "100%", maxWidth: "20rem", height: "auto"}}
        alt="bwb logo"
      />
    }
  >
    {children}
  </DefaultLayout>
);

const formRules = {
  shortName: [rules.isRequired],
  password: [rules.isRequired],
};

const initialValues = {shortName: "", password: ""};

const LoginScreen = ({location, navigate}) => {
  const [serverError, setServerError] = React.useState(null);
  const loadBrunnen = useLoadBrunnenDataFromServer();
  const maybeBrunnen = useBrunnenData();
  const afterLoginLocation = location.state && location.state.afterLoginLocation;
  React.useEffect(() => {
    if (maybeBrunnen.value) {
      const {pathname, search} = afterLoginLocation || {
        pathname: "/",
        search: "",
      };
      navigate(`${pathname}${search}`, {
        replace: true,
      });
    }
  }, [maybeBrunnen.value, afterLoginLocation, navigate]);

  const onSubmit = React.useCallback(
    ({shortName, password}) => {
      setServerError(null);
      return post("/login", {shortName, password}).then(
        res => {
          if (res.error) {
            console.log({err: res.error});
            setServerError(res.error);
          } else {
            return loadBrunnen();
          }
        },
        err => setServerError(errorToString(err))
      );
    },
    [loadBrunnen]
  );

  if (maybeBrunnen.value) {
    return <Layout>redirecting</Layout>;
  }

  return (
    <Layout>
      <Col
        fillParent
        as={Form}
        initialValues={initialValues}
        rules={formRules}
        onSubmit={onSubmit}
        sp={4}
      >
        <Card sp={3}>
          {serverError && (
            <Text color="red" size="2">
              {serverError}
            </Text>
          )}
          <Form.Input label="Brunnenteam" name="shortName" placeholder="z.b. Fri" />
          <Form.Input label="Passwort" name="password" type="password" placeholder="···" />
        </Card>
        <Push css={{maxHeight: "10rem"}} />
        <Col align="center">
          <Button type="submit">Anmelden</Button>
        </Col>
      </Col>
    </Layout>
  );
};

export default LoginScreen;

// const files = {
//   12323: {
//     id: 12323,
//     blob: null,
//     type: "image",
//     url: "https://s3bla.bwb.de/image/jpg",
//     bytesOnDevice: 0,
//     width: 800,
//     height: 600
//   },
//   14123: {
//     id: 14123,
//     blob: [Object object],
//     type: "audio",
//     url: null,
//     bytesOnDevice: 214213,
//   },
// };

import {setupStore} from "../lib/idbStore";

const {StoreProvider, useSet, useGet, useGetAll, DispatchContext} = setupStore("files");

export const FileContextProvider = StoreProvider;
export const useFileSetter = useSet;
export const useFileById = useGet;
export const useAllFiles = useGetAll;
export const FilesDispatchContext = DispatchContext;

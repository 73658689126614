import React from "react";
import {Col, Text} from ".";
import {useLoadBrunnenDataFromServer} from "../../models/brunnen";
import {unregister} from "../../serviceWorkerRegistration";

const Version = () => {
  const reload = useLoadBrunnenDataFromServer();
  const onClick = () => {
    reload();
    unregister().then(() => {
      window.location.reload();
    });
  };

  return (
    <Col align="center">
      <Text color="blue2_50p" align="center" size={0} onClick={onClick}>
        {process.env.REACT_APP_VERSION || "DEV-VERSION"}
      </Text>
    </Col>
  );
};

export default Version;

const getOrientation = file =>
  // adapted from https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
  new Promise(resolve => {
    var reader = new FileReader();
    reader.onload = function (e) {
      var view = new DataView(e.target.result);
      if (view.getUint16(0, false) !== 0xffd8) {
        return resolve(null);
      }
      var length = view.byteLength,
        offset = 2;
      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) return resolve(null);
        var marker = view.getUint16(offset, false);
        offset += 2;
        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return resolve(null);
          }

          var little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          var tags = view.getUint16(offset, little);
          offset += 2;
          for (var i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return resolve(view.getUint16(offset + i * 12 + 8, little));
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return resolve(null);
    };
    reader.readAsArrayBuffer(file);
  });

const clampDimensions = ({width, height, maxDim}) => {
  if (width > maxDim && width > height) {
    return {
      width: maxDim,
      height: Math.round((maxDim / width) * height),
    };
  } else if (height > maxDim) {
    return {
      width: Math.round((maxDim / height) * width),
      height: maxDim,
    };
  } else {
    return {width, height};
  }
};

// const processOrientation = (orientation, ctx, canvas) => {
//   const {width, height} = canvas;
//   if (orientation > 4) {
//     canvas.width = height;
//     canvas.height = width;
//   }
//   switch (orientation) {
//     case 2:
//       // horizontal flip
//       ctx.translate(canvas.width, 0);
//       ctx.scale(-1, 1);
//       break;
//     case 3:
//       // 180° rotate left
//       ctx.translate(canvas.width, canvas.height);
//       ctx.rotate(Math.PI);
//       break;
//     case 4:
//       // vertical flip
//       ctx.translate(0, canvas.height);
//       ctx.scale(1, -1);
//       break;
//     case 5:
//       // vertical flip + 90 rotate right
//       ctx.rotate(0.5 * Math.PI);
//       ctx.scale(1, -1);
//       break;
//     case 6:
//       // 90° rotate right
//       ctx.rotate(0.75 * Math.PI);
//       ctx.translate(0, -canvas.width);
//       break;
//     case 7:
//       // horizontal flip + 90 rotate right
//       ctx.rotate(0.5 * Math.PI);
//       ctx.translate(canvas.width, -canvas.height);
//       ctx.scale(-1, 1);
//       break;
//     case 8:
//       // 90° rotate left
//       ctx.rotate(-0.5 * Math.PI);
//       ctx.translate(-canvas.width, 0);
//       break;
//     default:
//       console.warn("Unknown orientation", orientation);
//   }
// };

const loadFileAsImage = file =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = URL.createObjectURL(file);
    img.onerror = reject;
  });

export const putImageOnCanvas = (file, maxDim) =>
  loadFileAsImage(file).then(img => {
    const {width, height} = clampDimensions({
      width: img.naturalWidth,
      height: img.naturalHeight,
      maxDim,
    });

    const imgCanvas = document.createElement("canvas");
    const imgContext = imgCanvas.getContext("2d");

    imgCanvas.width = width;
    imgCanvas.height = height;

    return getOrientation(file).then(orientation => {
      if (orientation !== null) {
        // Safari update seems to have taken care of it automatically
        // processOrientation(orientation, imgContext, imgCanvas);
      }
      imgContext.drawImage(img, 0, 0, width, height);
      imgContext.restore();
      return {imgCanvas, imgContext};
    });
  });

const storeImage = file =>
  putImageOnCanvas(file, 800).then(({imgCanvas}) => {
    const dataUrl = imgCanvas.toDataURL("image/jpeg", 0.5).split(",")[1];
    const binStr = atob(dataUrl);
    const len = binStr.length;
    const arr = new Uint8Array(len);

    for (var i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i);
    }
    const blob = new Blob([arr], {type: "image/jpeg"});
    return {blob, width: imgCanvas.width, height: imgCanvas.height};
  });

export default storeImage;

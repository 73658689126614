import React from "react";
import {Col, Text, Row} from "./ui";
import RawTextarea from "react-textarea-autosize";
import css from "@emotion/css";
import colors from "./ui/colors";

const labelStyle = css({
  display: "block",
  color: colors.gray3,
  transitionProperty: "color",
});
export const Label = ({hasErrors, ...rest}) => (
  <Text size={2} as="label" css={[labelStyle, hasErrors && {color: colors.red}]} {...rest} />
);

const inputStyle = css({
  flex: "auto",
  width: "100%",
  display: "block",
  background: "transparent",
  color: colors.blue2,
  border: "none",
  padding: "0.25rem 0",
  transitionProperty: "color",
  "::placeholder": {
    color: colors.gray7,
  },
  ":focus": {
    outline: "none",
  },
});
const inputErrorStyle = css({
  color: colors.red,
  ":focus": {
    color: colors.blue2,
  },
});
const readonlyStyle = css({
  color: colors.gray3,
});
const Input = React.forwardRef(({hasErrors, ...rest}, ref) => (
  <Text
    ref={ref}
    size={4}
    as="input"
    css={[inputStyle, hasErrors && inputErrorStyle, rest.readOnly && readonlyStyle]}
    passThrough={rest}
  />
));

const Textarea = React.forwardRef(({hasErrors, ...rest}, ref) => (
  <Text
    ref={ref}
    size={4}
    as={RawTextarea}
    css={[inputStyle, hasErrors && inputErrorStyle, rest.readOnly && readonlyStyle]}
    passThrough={{minRows: 2, ...rest}}
  />
));

const rowStyle = css({
  borderBottom: `1px solid ${colors.gray7}`,
  transitionProperty: "border-color",
});
const rowErrorStyle = css({
  borderBottomColor: colors.red,
  color: colors.red,
});
const rowFocusStyle = css({
  borderBottomColor: colors.blue2,
});

const InputRow = ({hasErrors, isFocussed, ...rest}) => (
  <Row
    sp={2}
    align="baseline"
    css={[rowStyle, hasErrors && rowErrorStyle, isFocussed && rowFocusStyle]}
    {...rest}
  />
);

const postfixStyle = css({
  color: colors.blue2,
  transitionProperty: "color",
  flex: "none",
});
const Postfix = ({hasErrors, readOnly, ...rest}) => (
  <Text
    size={4}
    as="label"
    css={[postfixStyle, hasErrors && {color: colors.red}, readOnly && readonlyStyle]}
    {...rest}
  />
);

const useFocussedState = ({onBlur: onParentBlur, onFocus: onParentFocus}) => {
  const [isFocussed, setIsFocussed] = React.useState(false);
  return [
    isFocussed,
    React.useCallback(
      e => {
        if (onParentFocus) onParentFocus(e);
        setIsFocussed(true);
      },
      [onParentFocus]
    ),
    React.useCallback(
      e => {
        if (onParentBlur) onParentBlur(e);
        setIsFocussed(false);
      },
      [onParentBlur]
    ),
  ];
};

export const FieldWithLabel = React.forwardRef(
  (
    {
      as: Comp,
      name,
      label = name,
      errors = [],
      postfix,
      type = "text",
      onFocus,
      onBlur,
      readOnly,
      ...rest
    },
    ref
  ) => {
    const hasErrors = errors.length > 0;
    const [isFocussed, onWrappedFocus, onWrappedBlur] = useFocussedState({onFocus, onBlur});

    return (
      <Col sp={2}>
        <Label htmlFor={name} hasErrors={hasErrors}>
          {label}
        </Label>
        <Col sp={1}>
          <InputRow isFocussed={isFocussed} hasErrors={hasErrors}>
            <Comp
              hasErrors={hasErrors}
              id={name}
              name={name}
              ref={ref}
              type={type}
              onFocus={onWrappedFocus}
              onBlur={onWrappedBlur}
              readOnly={readOnly}
              {...rest}
            />
            {postfix && (
              <Postfix readOnly={readOnly} hasErrors={hasErrors}>
                {postfix}
              </Postfix>
            )}
          </InputRow>
          {hasErrors && (
            <Row sp={1}>
              {errors.map((e, i) => (
                <Text size={1} color="red" key={i}>
                  {e}
                </Text>
              ))}
            </Row>
          )}
        </Col>
      </Col>
    );
  }
);

export const InputWithLabel = React.forwardRef((props, ref) => (
  <FieldWithLabel as={Input} ref={ref} {...props} />
));

export const TextareaWithLabel = React.forwardRef((props, ref) => (
  <FieldWithLabel as={Textarea} ref={ref} {...props} />
));

import React from "react";
import {Link, Match} from "@reach/router";
import DefaultLayout from "../../components/ui/DefaultLayout";
import {Col, Text, Row, Push} from "../../components/ui";
import TitleBar from "../../components/ui/TitleBar";
import colors from "../../components/ui/colors";

let NavLink = ({to, ...props}) => (
  <Match path={to}>
    {({match}) => (
      <Col px={2} py={2} css={match && {boxShadow: `0 -2px ${colors.blue2} inset`}}>
        <Text
          align="center"
          as={Link}
          to={to}
          {...props}
          size={2}
          color={match ? "blue2" : "gray3"}
        />
      </Col>
    )}
  </Match>
);

const Header = () => (
  <Col
    sp={4}
    px={3}
    css={{width: "100%", maxWidth: "40rem", marginLeft: "auto", marginRight: "auto"}}
  >
    <Text color="blue1" preset="bold" size={5} align="center">
      Aufgabenliste
    </Text>
    <Row sp={0} fillParent>
      <NavLink to="/tasks/pick">Brunnen verwalten</NavLink>
      <Push />
      <NavLink to="/tasks/brunnen">Messung am Brunnen</NavLink>
      <Push />
      <NavLink to="/tasks/strom">Strom&shy;messung</NavLink>
      <Push />
      <NavLink to="/tasks/send">Protokolle</NavLink>
    </Row>
  </Col>
);

const MainScreenLayout = ({children}) => (
  <DefaultLayout
    title={<TitleBar title="Brunnenmessungen" />}
    header={<Header />}
    noWaves
    headerProps={{
      pb: null,
      align: "stretch",
    }}
  >
    {children}
  </DefaultLayout>
);

export default MainScreenLayout;

import React from "react";
import css from "@emotion/css";
import {ReactComponent as ArrowBackSvg} from "../../assets/icons/arrow-back.svg";
import {ReactComponent as CloseSvg} from "../../assets/icons/close.svg";
import {ReactComponent as CaretDownSvg} from "../../assets/icons/caret-down.svg";
import {ReactComponent as ErgSvg} from "../../assets/icons/erg.svg";
import {ReactComponent as RwsSvg} from "../../assets/icons/rws.svg";
import {ReactComponent as StromSvg} from "../../assets/icons/strom.svg";
import {ReactComponent as LaborSvg} from "../../assets/icons/labor.svg";
import {ReactComponent as RegenerierungSvg} from "../../assets/icons/regenerierung.svg";
import {ReactComponent as MesstechnikSvg} from "../../assets/icons/messtechnik.svg";
import {ReactComponent as AufgabenlisteSvg} from "../../assets/icons/aufgabenliste.svg";
import {ReactComponent as FotoSvg} from "../../assets/icons/foto.svg";
import {ReactComponent as AudioSvg} from "../../assets/icons/audio.svg";
import {ReactComponent as NotizSvg} from "../../assets/icons/notiz.svg";
import {ReactComponent as PumpeSvg} from "../../assets/icons/pumpe.svg";
import {ReactComponent as ChecklisteSvg} from "../../assets/icons/checkliste.svg";
import {ReactComponent as PruefstandSvg} from "../../assets/icons/pruefstand.svg";
import {ReactComponent as ReparaturSvg} from "../../assets/icons/reparatur.svg";
import {ReactComponent as SyncSvg} from "../../assets/icons/sync.svg";
import {ReactComponent as TrashSvg} from "../../assets/icons/trash.svg";
import {classes} from "./classes";

const iconStyle = css({
  lineHeight: 1,
  height: "1em",
  width: "1em",
});

const svgStyle = css({stroke: "none", fill: "currentColor", height: "1em", width: "1em"});

const Icon = ({comp: SvgComp, color, size = 3, ...rest}) => {
  const cssList = [iconStyle, classes.fontSize[size]];
  if (color) cssList.push(classes.color[color]);
  return (
    <div css={cssList} {...rest}>
      <SvgComp css={svgStyle} />
    </div>
  );
};

export const IconArrowBack = props => <Icon comp={ArrowBackSvg} {...props} />;
export const IconClose = props => <Icon comp={CloseSvg} {...props} />;
export const IconCaretDown = props => <Icon comp={CaretDownSvg} {...props} />;
export const IconErg = props => <Icon comp={ErgSvg} {...props} />;
export const IconStrom = props => <Icon comp={StromSvg} {...props} />;
export const IconRws = props => <Icon comp={RwsSvg} {...props} />;
export const IconLabor = props => <Icon comp={LaborSvg} {...props} />;
export const IconRegenerierung = props => <Icon comp={RegenerierungSvg} {...props} />;
export const IconMesstechnik = props => <Icon comp={MesstechnikSvg} {...props} />;
export const IconAufgabenliste = props => <Icon comp={AufgabenlisteSvg} {...props} />;
export const IconFoto = props => <Icon comp={FotoSvg} {...props} />;
export const IconAudio = props => <Icon comp={AudioSvg} {...props} />;
export const IconNotiz = props => <Icon comp={NotizSvg} {...props} />;
export const IconPumpe = props => <Icon comp={PumpeSvg} {...props} />;
export const IconCheckliste = props => <Icon comp={ChecklisteSvg} {...props} />;
export const IconPruefstand = props => <Icon comp={PruefstandSvg} {...props} />;
export const IconReparatur = props => <Icon comp={ReparaturSvg} {...props} />;
export const IconSync = props => <Icon comp={SyncSvg} {...props} />;
export const IconTrash = props => <Icon comp={TrashSvg} {...props} />;

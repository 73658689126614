import React from "react";
import {Col, Text, Push, Row, Grid} from "../../components/ui";
import TransparentButton from "../../components/ui/TransparentButton";
import Card from "../../components/ui/Card";
import formatNumber from "../../lib/format-number";
import {getEinbautiefe} from "../../lib/brunnen-utils";
import CommentSection from "../../components/Comment";
import {InputWithLabel} from "../../components/Input";
import create from "zustand";
import {useAllTasks} from "../../models/tasks";
import {format} from "date-fns";

const Info = ({value, label}) => (
  <Col sp={1} align="center" css={{flex: "5rem 1 1"}}>
    <Text preset="semiBold" size={4} color="gray3" align="center">
      {value}
    </Text>
    <Text color="gray3" size={1} align="center">
      {label}
    </Text>
  </Col>
);

const Issue = ({isRws, bstdGestern, dataGeneratedAt}) => {
  if (isRws) {
    if (bstdGestern && bstdGestern > 0) {
      return (
        <Text color="red" align="center" preset="bold" size={2}>
          Laut Datenbank ist der Brunnen gestern ({format(dataGeneratedAt, "DD.MM.YYYY")}) für{" "}
          {bstdGestern} h in Betrieb gewesen. Bitte prüfen Sie, ob der Brunnen mindestens 24 h außer
          Betrieb war, bevor der Ruhewasserstand gemessen wird.
        </Text>
      );
    }
  } else {
    if ((bstdGestern && bstdGestern < 16) || bstdGestern === 0) {
      return (
        <Text color="red" align="center" preset="bold" size={2}>
          Laut Datenbank ist der Brunnen gestern ({format(dataGeneratedAt, "DD.MM.YYYY")}) weniger
          als 16 Stunden ({bstdGestern} h) in Betrieb gewesen. Bitte prüfen Sie, ob die
          Mindestlaufzeit für Ergiebigkeitsmessungen von 24 h eingehalten ist.
        </Text>
      );
    }
  }
  return null;
};

export const FormHeader = ({brunnen, measurementsLeft, isDone, isRws}) => {
  const getLabel = () => {
    if (isDone) return "Protokoll";
    if (measurementsLeft === 0) return "Messungen durchgeführt";
    if (measurementsLeft === 1) return "Eine Messung noch durchzuführen";
    return `${measurementsLeft} Messungen durchzuführen`;
  };

  const beweg = brunnen && brunnen.BEWEG_DATEN;
  const bstdGestern = beweg && beweg.BSTD_GESTERN;
  const yesterday = beweg && new Date(beweg.DATUM_GESTERN);

  const td = brunnen.TECH_DATEN;
  const depth = getEinbautiefe(brunnen);

  return (
    <Col sp={5} css={{width: "100%"}}>
      <Col sp={2} align="center">
        <Text color="blue1" preset="bold" size={5}>
          {brunnen.NAME}
        </Text>
        <Text color="blue1" size={2}>
          {getLabel()}
        </Text>
        {!isDone && <Issue isRws={isRws} bstdGestern={bstdGestern} dataGeneratedAt={yesterday} />}
      </Col>
      <Grid sp={2}>
        <Info
          value={depth !== null ? `${formatNumber(depth, {minimumFractionDigits: 2})} m` : "N.A."}
          label="Einbautiefe Pumpe"
        />
        <Info
          value={
            td && td.H_BAR ? `${formatNumber(td.H_BAR, {minimumFractionDigits: 1})} bar` : "N.A."
          }
          label="Nenndruck"
        />
        <Info value={td && td.Q_N_M3_H ? `${td.Q_N_M3_H} m³/h` : "N.A."} label="Nennleistung" />
        <Info
          value={td && td.I_A ? `${formatNumber(td.I_A, {minimumFractionDigits: 1})} A` : "N.A."}
          label="Nennstrom"
        />
      </Grid>
    </Col>
  );
};

export const CommentSectionToggler = ({name, setTask, task}) => {
  const [visible, setVisible] = React.useState(false);

  const rawComment = task.measurements[name].comment;

  const comment = rawComment || {audioIds: [], photoIds: [], note: null};
  const {audioIds, photoIds, note} = comment;

  const commentCount = audioIds.length + photoIds.length + (note === null ? 0 : 1);
  const getLabel = () => {
    if (commentCount === 0) return "Kommentar hinzufügen";
    if (commentCount === 1) return "1 Kommentar";
    return `${commentCount} Kommentare`;
  };

  const handleChange = React.useCallback(
    comment => {
      const existing = task.measurements && task.measurements[name];
      return setTask(task.id, {
        ...task,
        measurements: {
          ...task.measurements,
          [name]: {
            ...existing,
            comment,
            firstUpdated: (existing && existing.firstUpdated) || new Date(),
            lastUpdated: new Date(),
          },
        },
      });
    },
    [setTask, task, name]
  );

  if (task.done && !commentCount) return null;

  if (!visible) {
    return (
      <Col align="center">
        <TransparentButton onClick={() => setVisible(true)}>{getLabel()}</TransparentButton>
      </Col>
    );
  } else {
    return (
      <Col sp={3}>
        <Col align="center">
          <TransparentButton onClick={() => setVisible(false)}>schließen</TransparentButton>
        </Col>
        <CommentSection readOnly={task.done} comment={comment} onChange={handleChange} />
      </Col>
    );
  }
};

const defaultSerializer = {
  get: val => val,
  set: val => val,
};

const parseNumber = str => {
  if (str.match(/^-?\d+(:?[.,]\d+)?$/)) {
    return parseFloat(str.toString().replace(",", "."));
  } else {
    return Number.NaN;
  }
};

export const useField = ({serializer, task, name, minimumFractionDigits, setTask}) => {
  const taskVal = serializer.get(task.measurements[name].value);
  const [{val, isValid}, setVal] = React.useState(() => {
    if (taskVal) {
      const val = formatNumber(taskVal, {minimumFractionDigits});
      return {val, isValid: true};
    } else {
      return {val: null, isValid: false};
    }
  });

  const handleChange = rawNextStr => {
    const nextStr = rawNextStr.replace(/[^0-9,.-]/g, "");
    const asNum = parseNumber(nextStr);

    const value = Number.isNaN(asNum) ? null : asNum;
    setVal({val: nextStr, isValid: value !== null});
    if (value !== serializer.get(task.measurements[name].value)) {
      const existing = task.measurements && task.measurements[name];
      setTask(task.id, {
        ...task,
        lastSaved: new Date(),
        measurements: {
          ...task.measurements,
          [name]: {
            ...existing,
            value: serializer.set(value),
            firstUpdated: (existing && existing.firstUpdated) || new Date(),
            lastUpdated: new Date(),
          },
        },
      });
    }
  };

  const onReset = () => setVal({val: "", isValid: false});

  return [val, handleChange, onReset, isValid];
};

const [useFieldStore] = create(set => ({
  fields: {},
  setFieldFocusState: (name, focus) => set(state => ({fields: {...state.fields, [name]: focus}})),
}));

export const useFieldFocus = name => {
  const {setter, isFocussed} = useFieldStore(state => ({
    setter: state.setFieldFocusState,
    isFocussed: state.fields[name],
  }));
  React.useEffect(() => {
    return () => {
      // reset on unmount
      setter(name, null);
    };
  }, [setter, name]);
  return {
    isFocussed,
    fieldProps: {
      onFocus: () => setter(name, true),
      onBlur: () => setter(name, false),
    },
  };
};

export const useFieldFocusState = name => useFieldStore(state => state.fields[name]);

export const Field = ({
  name,
  placeholder,
  minimumFractionDigits,
  label,
  postfix,
  task,
  setTask,
  children,
  serializer = defaultSerializer,
}) => {
  const [val, setVal, , isValid] = useField({
    serializer,
    task,
    name,
    minimumFractionDigits,
    setTask,
  });
  const {fieldProps, isFocussed} = useFieldFocus(name);

  return (
    <Card>
      <Col sp={3}>
        <InputWithLabel
          type="text"
          label={label}
          name={name}
          placeholder={placeholder}
          postfix={postfix}
          inputMode="decimal"
          value={val || ""}
          onChange={task.done ? undefined : e => setVal(e.target.value)}
          readOnly={task.done}
          {...fieldProps}
        />
        {val && !isValid && !isFocussed && (
          <Text size={2} color="red">
            Kein gültiger Zahlenwert
          </Text>
        )}
        {children && <Col sp={1}>{children}</Col>}
        <CommentSectionToggler task={task} setTask={setTask} name={name} />
      </Col>
    </Card>
  );
};

export const Hint = ({label, children}) => (
  <Row sp={2} align="baseline">
    <Text size={1} color="gray3">
      {label}
    </Text>
    <Push />
    <Text size={3} color="gray3">
      {children}
    </Text>
  </Row>
);

export const PlausiHint = ({name, task, shown, children}) => {
  const focussed = useFieldFocusState(name);
  const currVal = task.measurements[name].value;
  const isShown = !focussed && currVal !== undefined && currVal !== null && shown(currVal);
  return isShown ? (
    <Text size={2} color="red">
      {children}
    </Text>
  ) : null;
};

const emptyTasks = {};

export const useAvgQ = brunnen => {
  const tasks = useAllTasks().value || emptyTasks;
  const brunnenTasks = React.useMemo(
    () =>
      brunnen &&
      Object.values(tasks).filter(t => t.brunnenSTO === brunnen.BRU_STO_KENNZ && !t.done),
    [brunnen, tasks]
  );
  const lm = brunnen && brunnen.LAST_MESS;
  const td = brunnen && brunnen.TECH_DATEN;
  if (!(lm && lm.Q_NEU)) return null;
  if (!(td && td.Q_N_M3_H)) return null;
  if (brunnenTasks.length !== 2) return null;
  const ergTask = brunnenTasks.find(t => t.type === "ERG");
  const stromTask = brunnenTasks.find(t => t.type === "STROM");
  if (!ergTask || !stromTask) return null;
  const ergV = ergTask.measurements.zaehlerstand.value;
  const Q_NEU = ergV && ergV.value;
  if (!Q_NEU) return null;
  const Q_MENGE = Q_NEU - lm.Q_NEU;
  const stromV = stromTask.measurements.zaehlerstand.value;
  if (!stromV) return null;
  if (stromV.type === "absolute" && !stromV.prev) return null;
  const BETR_H = stromV.type === "relative" ? stromV.value : stromV.value - stromV.prev;
  return {Q_MENGE, BETR_H, Q_AVG: Q_MENGE / BETR_H};
};

import "./lib/polyfills";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorkerRegistration.register({
  onUpdate: reg => {
    if (reg.waiting) {
      reg.waiting.postMessage({action: "SKIP_WAITING"});
      setTimeout(() => {
        window.location.reload();
      }, 50);
    } else {
      console.warn("Found no 'waiting' within", reg);
    }
  },
});

import React from "react";

const getProcessor = (context, bufferSize, numberOfInputChannels, numberOfOutputChannels) => {
  return context.createScriptProcessor
    ? context.createScriptProcessor(bufferSize, numberOfInputChannels, numberOfOutputChannels)
    : context.createJavaScriptNode(bufferSize, numberOfInputChannels, numberOfOutputChannels);
};

function bufferToWav(abuffer, len) {
  const setUint16 = data => {
    view.setUint16(pos, data, true);
    pos += 2;
  };

  const setUint32 = data => {
    view.setUint32(pos, data, true);
    pos += 4;
  };

  const numOfChan = abuffer.numberOfChannels;
  const length = len * numOfChan * 2 + 44;
  const buffer = new ArrayBuffer(length);
  const view = new DataView(buffer);
  const channels = [];
  let offset = 0;
  let pos = 0;

  // write WAVE header
  setUint32(0x46464952); // "RIFF"
  setUint32(length - 8);
  setUint32(0x45564157); // "WAVE"

  setUint32(0x20746d66); // "fmt " chunk
  setUint32(16); // length = 16
  setUint16(1); // PCM (uncompressed)
  setUint16(numOfChan);
  setUint32(abuffer.sampleRate);
  setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
  setUint16(numOfChan * 2); // block-align
  setUint16(16); // 16-bit (hardcoded)

  setUint32(0x61746164); // "data" - chunk
  setUint32(length - pos - 4); // chunk length

  // write interleaved data
  for (let i = 0; i < abuffer.numberOfChannels; i++) channels.push(abuffer.getChannelData(i));

  while (pos < length) {
    for (let i = 0; i < numOfChan; i++) {
      // interleave channels
      let sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
      sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
      view.setInt16(pos, sample, true); // write 16-bit sample
      pos += 2;
    }
    offset++; // next source sample
  }

  return new Blob([buffer], {type: "audio/wav"});
}

const RealAudioContext = window.AudioContext || window.webkitAudioContext;

const concatBuffers = (singleChannelBuffers, context) => {
  const len = singleChannelBuffers.reduce((s, b) => s + b.length, 0);
  const newBuf = context.createBuffer(1, len, context.sampleRate || 44100);
  const concatChannel = newBuf.getChannelData(0);
  let offset = 0;
  for (let i = 0; i < singleChannelBuffers.length; i += 1) {
    const b = singleChannelBuffers[i];
    concatChannel.set(b, offset);
    offset += b.length;
  }
  return newBuf;
};

const canRecord = context => {
  if (!("mediaDevices" in navigator)) {
    console.warn("no Support for 'navigator.mediaDevices'");
    return false;
  }
  if (!("getUserMedia" in navigator.mediaDevices)) {
    console.warn("no Support for 'navigator.mediaDevices.getUserMedia'");
    return false;
  }
  if (!("createMediaStreamSource" in context)) {
    console.warn("no Support for 'AudioContext.createMediaStreamSource'");
    return false;
  }
  if (!("createScriptProcessor" in context || "createJavaScriptNode" in context)) {
    console.warn("no Support for 'AudioContext.(createScriptProcessor|createJavaScriptNode)'");
    return false;
  }
  return true;
};

const startRecording = context =>
  navigator.mediaDevices.getUserMedia({audio: true, video: false}).then(stream => {
    const source = context.createMediaStreamSource(stream);
    const processor = getProcessor(context, 1024, 1, 1);
    const buffers = [];
    let len = 0;

    processor.onaudioprocess = e => {
      const buf = e.inputBuffer;
      buffers.push(new Float32Array(buf.getChannelData(0)));
      len += buf.length;
    };

    source.connect(processor);
    processor.connect(context.destination);

    const kill = () => {
      processor.disconnect(context.destination);
      source.disconnect(processor);
      stream.getTracks().forEach(track => track.stop());
    };

    return {
      stop: () => {
        kill();
        return len ? bufferToWav(concatBuffers(buffers, context), len) : null;
      },
      abort: () => {
        kill();
      },
    };
  });

const useResetNextFrame = () => {
  const justActivatedRef = React.useRef(false);
  return [
    justActivatedRef,
    () => {
      justActivatedRef.current = true;
      requestAnimationFrame(() => {
        justActivatedRef.current = false;
      });
    },
  ];
};

let cachedContext = null;

const useAudioRecorder = addAudio => {
  const [error, setError] = React.useState(null);
  const [pressedAt, setPressetAt] = React.useState(null);
  const recorderRef = React.useRef(null);
  const fallbackInputRef = React.useRef(null);
  // to prevent clicking on the fallbackInputRef twice via onMouseDown & onPointerDown
  const [justClickedRef, setJustClicked] = useResetNextFrame();

  React.useEffect(() => {
    const handler = e => {
      if (!pressedAt) return;
      const now = new Date().getTime();
      if (now - pressedAt < 1000) {
        setError("short");
        if (recorderRef.current) recorderRef.current.abort();
      } else {
        if (recorderRef.current) {
          const wavBlob = recorderRef.current.stop();
          if (wavBlob) {
            addAudio({blob: wavBlob, size: wavBlob.size});
          } else {
            console.log("no wav recorded");
          }
        }
      }
      recorderRef.current = null;
      setPressetAt(null);
    };
    window.addEventListener("mouseup", handler);
    window.addEventListener("touchend", handler);
    window.addEventListener("pointercancel", handler);
    return () => {
      window.removeEventListener("mouseup", handler);
      window.removeEventListener("touchend", handler);
      window.removeEventListener("pointercancel", handler);
    };
  }, [pressedAt, addAudio]);

  const handleDown = () => {
    setError(null);

    cachedContext = cachedContext || (RealAudioContext && new RealAudioContext());
    if (!cachedContext || !canRecord(cachedContext)) {
      if (fallbackInputRef.current) {
        if (!justClickedRef.current) {
          fallbackInputRef.current.click();
          setJustClicked();
        }
      }
    } else {
      setPressetAt(new Date().getTime());
      startRecording(cachedContext).then(
        recorder => {
          if (recorderRef.current) {
            recorderRef.current.abort();
          }
          recorderRef.current = recorder;
        },
        e => setError(e.message || e.toString())
      );
    }
  };

  const handleFallbackAudio = e => {
    const file = (e.target.files || [])[0];
    if (file) {
      addAudio({blob: file, size: file.size});
    }
  };

  return {
    buttonProps: {onMouseDown: handleDown, onTouchStart: handleDown, style: {touchAction: "none"}},
    pressed: !!pressedAt,
    fallbackEl: (
      <input
        ref={fallbackInputRef}
        type="file"
        accept="audio/*"
        capture
        css={{display: "none", position: "absolute", left: -10000, zIndex: -1}}
        onChange={handleFallbackAudio}
      />
    ),
    error,
  };
};

export default useAudioRecorder;

import React from "react";
import {estimateStorage} from "../lib/client-storage";
import {useAllFiles, FilesDispatchContext, useFileSetter} from "../models/files";
import {Row, Text, Push} from "./ui";
import formatNumber from "../lib/format-number";
import TransparentButton from "./ui/TransparentButton";
import {post} from "../lib/requests";
import {useNetworkStatus} from "../lib/hooks/useNetworkStatus";

export const useStorageInfo = () => {
  const [estimationInfo, setEstimationInfo] = React.useState(null);
  const [storageInfo, setStorageInfo] = React.useState(null);
  React.useEffect(() => {
    estimateStorage().then(
      ({usage, quota}) => {
        setEstimationInfo({usage, quota});
      },
      () => setEstimationInfo(null)
    );
  }, []);

  const maybeFiles = useAllFiles();
  React.useEffect(() => {
    const unsyncedFiles = maybeFiles.value
      ? Object.values(maybeFiles.value).filter(file => file && !file.url)
      : [];
    if (estimationInfo) {
      setStorageInfo({...estimationInfo, unsyncedFiles});
    } else {
      const usage = unsyncedFiles.reduce((s, f) => s + f.bytesOnDevice, 0);
      setStorageInfo({usage, quota: 50 * 1000 ** 2, unsyncedFiles});
    }
  }, [estimationInfo, maybeFiles.value]);
  return storageInfo;
};

const FileSize = ({bytes}) => (
  <Row sp={0} align="baseline">
    <Text preset="bold" color="white" size={2}>
      {formatNumber(bytes / 1024 ** 2)}
    </Text>
    <Text color="white" size={0}>
      MB
    </Text>
  </Row>
);

const typeToExt = type => {
  if (!type) return null;
  const m = type.match(/\w+\/(\w+)/);
  if (!m) return null;
  return `.${m[1]}`;
};

function blobToFile(theBlob, fileName) {
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
}

const doUpload = (url, blob, type, filename) =>
  new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.open("PUT", url, true);
    xhr.setRequestHeader("Content-Type", type || "");
    xhr.send(blobToFile(blob, filename));
    xhr.onerror = rej;
    xhr.onload = function() {
      this.status < 400 ? res() : rej(new Error(this.responseText || "Upload Error"));
    };
  });

const uploadFile = async file => {
  const ext = typeToExt(file.type) || "";
  const filename = `${file.id}${ext}`;
  const {signedUrl, finalUrl} = await post("/create-upload-url", {filename});
  await doUpload(signedUrl, file.blob, file.type, filename);
  return finalUrl;
};

const SyncButton = ({unsyncedFiles}) => {
  const setFile = useFileSetter();
  const [progress, setProgress] = React.useState(null);
  const {isOnline} = useNetworkStatus();

  const handleSync = async () => {
    setProgress(() => ({done: 0, total: unsyncedFiles.length}));
    for (const file of unsyncedFiles) {
      const url = await uploadFile(file);
      await setFile(file.id, {...file, url, blob: null});
      setProgress(p => ({...p, done: p.done + 1}));
    }
    setProgress(null);
  };

  const getLabel = () => {
    if (progress) return `uploaded ${progress.done}/${progress.total}`;
    if (!isOnline) return "Kein Internet";
    if (unsyncedFiles.length === 1) return "1 Datei auf Server laden";
    return `${unsyncedFiles.length} Dateien auf Server laden`;
  };
  return (
    <TransparentButton onDark onClick={handleSync} disabled={!!progress}>
      {getLabel()}
    </TransparentButton>
  );
};

const FileSyncerWithinContext = () => {
  const storageInfo = useStorageInfo();
  if (!storageInfo) return null;
  const {usage, quota, unsyncedFiles} = storageInfo;

  if (!unsyncedFiles.length) return null;

  const ratio = usage / quota;

  return (
    <Row
      bg={ratio > 0.8 ? "red" : "blue2"}
      px={3}
      py={2}
      elevation={2}
      align="center"
      breaking
      sp={2}
    >
      <Row sp={3} align="baseline" breaking={0}>
        <Text preset="semiBold" color="white" size={1}>
          Genutzter Geräte-Speicher:
        </Text>
        <Row sp={2} align="baseline">
          <FileSize bytes={usage} />
          <Text color="white" size={1}>
            von
          </Text>
          <FileSize bytes={quota} />
        </Row>
      </Row>
      <Push />
      <SyncButton unsyncedFiles={unsyncedFiles} />
    </Row>
  );
};

const FileSyncer = () => {
  const ctx = React.useContext(FilesDispatchContext);
  return ctx ? <FileSyncerWithinContext /> : null;
};

export default FileSyncer;

import React from "react";
import {Store, set, get, keys, clear, del} from "idb-keyval";

// This module abstracts access to a model.
// It creates a ContextProvider as well as hooks for getting and setting elements from
// The underlying indexdb key value store
// Data that is being fetched is stored in a reducer to avoid loading states when accessing data a second time

const createIdbStore = storeName => {
  // can't have multiple stores in same db due to
  // https://github.com/jakearchibald/idb-keyval/issues/31
  const store = new Store(storeName, storeName);

  return {
    get: key => get(key, store),
    set: (key, val) => set(key, val, store),
    keys: () => keys(store),
    clear: () => clear(store),
    del: key => del(key, store),
  };
};

const removeKey = (obj, key) => {
  const newObj = {...obj};
  delete newObj[key];
  return newObj;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFull":
      return {
        ...state,
        knowsAll: true,
        values: action.payload,
      };
    case "set":
      return {
        ...state,
        values: {
          ...state.values,
          [action.payload.key]: action.payload.val,
        },
      };
    case "del":
      return {
        ...state,
        values: removeKey(state.values, action.payload.key),
      };
    case "clear":
      return {
        ...state,
        knowsAll: true,
        values: {},
      };
    default:
      throw new Error("unknown action " + action.type);
  }
};

const initialState = {knowsAll: false, values: {}};

export const setupStore = storeName => {
  const StateContext = React.createContext(initialState);
  const DispatchContext = React.createContext(null);

  const storage = createIdbStore(storeName);
  const useSet = () => {
    const dispatch = React.useContext(DispatchContext);
    return React.useCallback(
      async (key, val) => {
        if (val) {
          await storage.set(key, val);
          dispatch({type: "set", payload: {key, val}});
        } else {
          await storage.del(key);
          dispatch({type: "del", payload: {key}});
        }
      },
      [dispatch]
    );
  };

  const useClear = () => {
    const dispatch = React.useContext(DispatchContext);
    return React.useCallback(async () => {
      await storage.clear();
      dispatch({type: "clear", payload: null});
    }, [dispatch]);
  };

  const useGetAll = () => {
    const dispatch = React.useContext(DispatchContext);
    const state = React.useContext(StateContext);
    return React.useMemo(() => {
      if (state.knowsAll) return {isLoading: false, value: state.values};
      storage.keys().then(keys => {
        Promise.all(
          keys.map(key =>
            state[key] !== undefined
              ? Promise.resolve({key, val: state[key]})
              : storage.get(key).then(val => ({key, val}))
          )
        ).then(keysAndValues => {
          dispatch({
            type: "setFull",
            payload: keysAndValues.reduce((m, {key, val}) => {
              m[key] = val;
              return m;
            }, {}),
          });
        });
      });
      return {isLoading: true};
    }, [state, dispatch]);
  };

  const useGet = key => {
    const dispatch = React.useContext(DispatchContext);
    const state = React.useContext(StateContext);

    const presentInState = key in state.values;
    const value = state.values[key];
    return React.useMemo(() => {
      if (presentInState) return {isLoading: false, value};
      storage.get(key).then(val => dispatch({type: "set", payload: {key, val}}));
      return {isLoading: true};
    }, [presentInState, key, value, dispatch]);
  };

  const StoreProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    return (
      <StateContext.Provider value={state}>
        <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
      </StateContext.Provider>
    );
  };

  return {
    StoreProvider,
    useSet,
    useGet,
    useGetAll,
    useClear,
    DispatchContext,
  };
};

import React from "react";
import colors from "./colors";
import {Text} from ".";
import {ButtonOrLinkComp, rawButtonStyles} from "./buttonHelpers";
import css from "@emotion/css";

const styles = {
  base: css({
    ...rawButtonStyles,
    boxShadow: `0 0 0 0.2rem transparent, 0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)`,

    ":focus": {
      outline: "none",
      boxShadow: `0 0 0 0.2rem ${colors.blue2_50p}, 0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)`,
    },
  }),
  squared: {
    md: css({padding: "0.6rem", borderRadius: "0.2rem"}),
    lg: css({padding: "1rem"}),
  },
  nonSquared: {
    sm: css({padding: "0.3rem 0.5rem 0.2rem", borderRadius: "0.2rem"}),
    md: css({padding: "0.7rem 1rem 0.6rem", borderRadius: "0.2rem"}),
    lg: css({padding: "1rem 4rem"}),
  },
  colors: {
    default: css({
      backgroundColor: colors.green2,
      ":hover:not(:disabled)": {
        backgroundColor: colors.green1,
      },
    }),
    warn: css({
      backgroundColor: colors.warn2,
      ":hover:not(:disabled)": {
        backgroundColor: colors.warn1,
      },
    }),
  },
};

const sizeToFontSize = {
  lg: 3,
  md: 2,
  sm: 1,
};

const Button = ({children, square, size = "lg", color = "default", ...rest}) => (
  <ButtonOrLinkComp
    css={[
      styles.base,
      styles.colors[color],
      square ? styles.squared[size] : styles.nonSquared[size],
    ]}
    {...rest}
  >
    {typeof children === "string" ? (
      <Text preset="button" size={sizeToFontSize[size]}>
        {children}
      </Text>
    ) : (
      children
    )}
  </ButtonOrLinkComp>
);

export default Button;

import {useEffect, useMemo, useRef, useState} from "react";

const getRawStorageMap = (prefix, storageGetter) => {
  let storage;
  try {
    storage = storageGetter();
  } catch {
    return {
      storageGet() {
        return null;
      },
      storageSet() {
        return false;
      },
      storageRemove() {
        return false;
      },
    };
  }

  return {
    storageGet(key) {
      try {
        const content = storage.getItem(`${prefix}${key}`);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    storageSet(key, val) {
      try {
        storage.setItem(`${prefix}${key}`, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    storageRemove(key) {
      try {
        storage.removeItem(`${prefix}${key}`);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

const getRawStorage = (key, storageGetter) => {
  let storage;
  try {
    storage = storageGetter();
  } catch {
    return {
      get() {
        return null;
      },
      set() {
        return false;
      },
      remove() {
        return false;
      },
    };
  }
  return {
    get() {
      try {
        const content = storage.getItem(key);
        return content ? JSON.parse(content) : null;
      } catch (e) {
        return null;
      }
    },

    set(val) {
      try {
        storage.setItem(key, JSON.stringify(val));
        return true;
      } catch (e) {
        return false;
      }
    },

    remove() {
      try {
        storage.removeItem(key);
        return true;
      } catch (e) {
        return false;
      }
    },
  };
};

export const getLocalStorageMap = prefix => getRawStorageMap(prefix, () => window.localStorage);
export const getSessionStorageMap = prefix => getRawStorageMap(prefix, () => window.sessionStorage);

export const singleKeyLocalStorage = key => getRawStorage(key, () => window.localStorage);
export const singleKeySessionStorage = key => getRawStorage(key, () => window.sessionStorage);

const lsStorage = getRawStorageMap("", () => window.localStorage);

const getStorageValOrDefaultWithKey = (key, defaultVal) => {
  if (!key) return {key, value: null};
  const storageVal = lsStorage.storageGet(key);
  const value = storageVal === null ? defaultVal : storageVal;
  return {key, value};
};

export const useLocalStorageState = (key, defaultVal) => {
  const [data, setData] = useState(() => getStorageValOrDefaultWithKey(key, defaultVal));
  let nextVal = null;

  if (key !== data.key) {
    nextVal = getStorageValOrDefaultWithKey(key, defaultVal);
    setData(nextVal);
  }

  const defaultValueRef = useRef(defaultVal);
  useEffect(() => {
    defaultValueRef.current = defaultVal;
  }, [defaultVal]);

  const handlers = useMemo(
    () => ({
      setVal: next => {
        if (typeof next === "function") {
          setData(prev => {
            const val = next(prev.value);
            lsStorage.storageSet(key, val);
            return {key, value: val};
          });
        } else {
          lsStorage.storageSet(key, next);
          setData({key, value: next});
        }
      },
      clear: () => {
        lsStorage.storageRemove(key);
        setData({key, value: defaultValueRef.current});
      },
    }),
    [key]
  );

  return [nextVal ? nextVal.value : data.value, handlers.setVal, {clear: handlers.clear}];
};

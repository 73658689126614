import React from "react";
import styled from "@emotion/styled";
import {Col} from ".";
import FileSyncer from "../FileSyncer";
import Version from "./Version";
import BackdropImg from "./backdrop_white.svg";

const Waves = styled("div")({
  height: 0,
  position: "relative",
  top: -1,
  paddingTop: `${(44 / 768) * 100}%`,
  backgroundImage: `url(${BackdropImg})`,
  backgroundPosition: "top center",
});

const DefaultLayout = ({title, header, children, headerProps, noWaves}) => (
  <Col bg="gray9" css={{minHeight: "100vh"}}>
    {title}
    {header ? (
      <React.Fragment>
        <Col align="center" px={5} py={6} bg="white" {...headerProps}>
          {header}
        </Col>
        {!noWaves && <Waves />}
      </React.Fragment>
    ) : null}
    <Col
      px={3}
      py={6}
      sp={3}
      fillParent
      css={{width: "100%", maxWidth: "40rem", marginLeft: "auto", marginRight: "auto"}}
    >
      {children}
    </Col>
    <Col py={0}>
      <Version />
    </Col>
    <FileSyncer />
  </Col>
);

export default DefaultLayout;

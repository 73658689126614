import React from "react";
import {Col, Text, Push, Row} from "../../components/ui";
import MainScreenLayout from "../../components/ui/MainScreenLayout";
import {SelectWithLabel} from "../../components/Select";
import Button from "../../components/ui/Button";
import {format, addDays} from "date-fns";
import {useAllTasks, useTaskSetter, newErgTask, newStromTask, newRwsTask} from "../../models/tasks";
import {useBrunnenData} from "../../models/brunnen";
import {Label} from "../../components/Input";
import css from "@emotion/css";
import colors from "../../components/ui/colors";
import useStoToBrunnen from "../../lib/hooks/useStoToBrunnen";
import {isDataOld} from "../../components/DataFreshness";
import Slider from "../../components/Slider";
import {useLocalStorageState} from "../../lib/storage";

const laneStyles = {
  base: css({
    borderLeft: `0.3rem solid`,
  }),
  byStatus: {
    green: css({borderLeftColor: colors.green2}),
    red: css({borderLeftColor: colors.red}),
    missing: css({borderLeftColor: colors.gray5}),
    tooOld: css({borderLeftColor: colors.gray5}),
  },
};

const Lane = ({selected, status, ...rest}) => (
  <Row
    css={[laneStyles.base, selected && laneStyles.selected, laneStyles.byStatus[status]]}
    bg={selected ? "selectedBlue" : "white"}
    sp={3}
    px={4}
    py={3}
    align="center"
    elevation={1}
    {...rest}
  />
);

const brunnenToStatus = (brunnen, dataInfo) => {
  if (isDataOld(dataInfo)) return "tooOld";
  if (!brunnen || !brunnen.BEWEG_DATEN || brunnen.BEWEG_DATEN.BSTD_GESTERN === undefined)
    return "missing";
  return brunnen.BEWEG_DATEN.BSTD_GESTERN >= 16 ? "green" : "red";
};

const statusToColor = {
  green: "green2",
  red: "red",
  missing: "gray5",
  tooOld: "gray5",
};

const StatusMessage = ({status, brunnen, selected}) => {
  const getMsg = () => {
    if (status === "missing") return "Keine Daten";
    if (status === "tooOld") return "Keine aktuellen Daten";
    return `${brunnen.BEWEG_DATEN.BSTD_GESTERN}h`;
  };

  return (
    <Col sp={0}>
      <Text color={selected ? "blue2" : statusToColor[status]} size={1}>
        Gestern in Betrieb
      </Text>
      <Text preset="bold" color={selected ? "blue2" : statusToColor[status]} size={1}>
        {getMsg()}
      </Text>
    </Col>
  );
};

const BrunnenLane = ({addNonRwsSto, addRwsSto, brunnen, existingTasksTypes, dataInfo}) => {
  const status = brunnenToStatus(brunnen, dataInfo);
  const hasRws = existingTasksTypes.RWS;
  const hasBothNonRws = existingTasksTypes.STROM && existingTasksTypes.ERG;
  const missingOneNonRws =
    !hasBothNonRws && ((existingTasksTypes.STROM && "ERG") || (existingTasksTypes.ERG && "STROM"));
  const selected = hasRws || hasBothNonRws;

  return (
    <Lane selected={selected} status={status}>
      <Col sp={1}>
        <Text preset="semiBold" color={selected ? "gray3" : "blue2"} size={4}>
          {brunnen.NAME}
        </Text>
        <Row sp={4}>
          <StatusMessage status={status} brunnen={brunnen} />
          <Col sp={0} align="baseline">
            <Text color={selected ? "gray3" : "blue2"} size={1}>
              zuletzt gemessen
            </Text>
            <Text preset="bold" color={selected ? "gray3" : "blue2"} size={1}>
              {brunnen.LAST_MESS ? format(brunnen.LAST_MESS.MESS_DAT, "DD.MM.YYYY") : "–"}
            </Text>
          </Col>
        </Row>
      </Col>
      <Push />
      {selected ? (
        <Text color="gray5" size={1}>
          {hasRws ? "RWS" : "ERG & STROM"} in Aufgabenliste
        </Text>
      ) : missingOneNonRws ? (
        <Button key="select" size="md" onClick={() => addNonRwsSto(brunnen.BRU_STO_KENNZ)}>
          {`${missingOneNonRws} hinzufügen`}
        </Button>
      ) : (
        <Row sp={2}>
          <Button size="md" onClick={() => addRwsSto(brunnen.BRU_STO_KENNZ)}>
            <Text preset="button" size={2}>
              RWS
              <br />
              hinzufügen
            </Text>
          </Button>
          <Button size="md" onClick={() => addNonRwsSto(brunnen.BRU_STO_KENNZ)}>
            <Text preset="button" size={2}>
              ERG & STROM
              <br />
              hinzufügen
            </Text>
          </Button>
        </Row>
      )}
    </Lane>
  );
};

const BrunnenPicker = ({
  availableBrunnen,
  nonDoneStoToTaskType,
  dataInfo,
  addNonRwsSto,
  addRwsSto,
}) => (
  <Col sp={0}>
    {availableBrunnen.map(b => {
      return (
        <BrunnenLane
          key={b.BRU_STO_KENNZ}
          brunnen={b}
          addNonRwsSto={addNonRwsSto}
          addRwsSto={addRwsSto}
          existingTasksTypes={nonDoneStoToTaskType[b.BRU_STO_KENNZ] || {}}
          dataInfo={dataInfo}
        />
      );
    })}
  </Col>
);

const sliderOptions = [
  {label: "0T", value: 0},
  {label: "1T", value: 1},
  {label: "1W", value: 7},
  {label: "2W", value: 14},
  {label: "1M", value: 30},
  {label: "2M", value: 60},
  {label: "3M", value: 90},
  {label: "6M", value: 180},
];

const useSlider = () => {
  const [value, setValue] = React.useState(90);

  const slider = (
    <Col sp={2}>
      <Row align="baseline">
        <Label>Letzte Messung vor:</Label>
        <Push />
        <Row sp={1}>
          <Text color="blue2" size={2}>
            {format(addDays(new Date(), -value), "DD.MM.YYYY")}
          </Text>
          <Text color="gray5" size={2}>
            ({value} Tag{value === 1 ? "" : "e"})
          </Text>
        </Row>
      </Row>

      <Col pt={4}>
        <Slider options={sliderOptions} value={value} onChange={v => setValue(v)} />
      </Col>
    </Col>
  );
  return {value, slider};
};

const PickBrunnenScreen = () => {
  const maybeBrunnen = useBrunnenData();
  const stoToBrunnen = useStoToBrunnen();
  const galeriesToBrunnen = React.useMemo(() => {
    const mapping = {};
    if (!maybeBrunnen.value) return mapping;
    maybeBrunnen.value.brunnen.forEach(b => {
      const galKey = `${b.WERK} - ${b.GALERIE}`;
      const arr = (mapping[galKey] = mapping[galKey] || []);
      arr.push(b);
    });
    return mapping;
  }, [maybeBrunnen.value]);

  const [selectedGallery, setSelectedGallery] = useLocalStorageState(
    "galery",
    Object.keys(galeriesToBrunnen)[0]
  );
  const {slider, value: daysAgo} = useSlider();

  const handlePickGallery = e => {
    const val = e.target.value;
    if (val !== selectedGallery) {
      setSelectedGallery(val);
    }
  };

  const availableBrunnen = React.useMemo(
    () =>
      ((selectedGallery && galeriesToBrunnen[selectedGallery]) || [])
        .filter(b => !b.LAST_MESS || new Date(b.LAST_MESS.MESS_DAT) < addDays(new Date(), -daysAgo))
        .sort((b1, b2) => (b1.NAME > b2.Name ? -1 : 1)),
    [selectedGallery, galeriesToBrunnen, daysAgo]
  );
  const maybeTasks = useAllTasks();

  const nonDoneStoToTaskType = React.useMemo(() => {
    const stoToTaskType = {};
    Object.values(maybeTasks.value || {}).forEach(task => {
      if (task.done) return;
      if (!stoToTaskType[task.brunnenSTO]) stoToTaskType[task.brunnenSTO] = {};
      stoToTaskType[task.brunnenSTO][task.type] = true;
    });
    return stoToTaskType;
  }, [maybeTasks]);

  const setTask = useTaskSetter();

  const addNonRwsSto = sto => {
    const existingTasks = nonDoneStoToTaskType[sto] || {};
    const promises = [];
    if (!existingTasks.ERG) {
      const brunnen = stoToBrunnen[sto];
      const ergTask = newErgTask(sto, brunnen);
      promises.push(setTask(ergTask.id, ergTask));
    }
    if (!existingTasks.STROM) {
      const stromTask = newStromTask(sto);
      promises.push(setTask(stromTask.id, stromTask));
    }
    return Promise.all(promises);
  };

  const addRwsSto = sto => {
    const brunnen = stoToBrunnen[sto];
    const rwsTask = newRwsTask(sto, brunnen);
    return setTask(rwsTask.id, rwsTask);
  };

  return (
    <MainScreenLayout>
      <Col fillParent sp={5}>
        <Col sp={5}>
          <SelectWithLabel
            label="Galerie"
            placeholder="Galerie auswählen"
            value={selectedGallery}
            onChange={handlePickGallery}
          >
            {Object.keys(galeriesToBrunnen)
              .sort()
              .map(galKey => (
                <option key={galKey} value={galKey}>
                  {galKey}
                </option>
              ))}
          </SelectWithLabel>
          {slider}
        </Col>
        {availableBrunnen.length > 0 && (
          <BrunnenPicker
            availableBrunnen={availableBrunnen}
            addNonRwsSto={addNonRwsSto}
            addRwsSto={addRwsSto}
            nonDoneStoToTaskType={nonDoneStoToTaskType}
            dataInfo={maybeBrunnen.value && maybeBrunnen.value.info}
          />
        )}
      </Col>
    </MainScreenLayout>
  );
};

export default PickBrunnenScreen;

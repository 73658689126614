import React from "react";
import styled from "@emotion/styled";
import colors from "./colors";
import {ButtonOrLinkComp, rawButtonStyles} from "./buttonHelpers";
import css from "@emotion/css";

const defBoxShadow = "0 0.1rem 0.1rem rgba(0,0,0,0.1), 0 0.1rem 0.5rem rgba(0,0,0,0.05)";

const IconButtonContainer = styled(ButtonOrLinkComp)(rawButtonStyles, ({disabled}) => ({
  boxShadow: `0 0 0 0.2rem transparent, ${defBoxShadow}`,
  padding: "0.5rem",
  backgroundColor: colors.green2,
  ":hover": !disabled && {
    backgroundColor: colors.green1,
  },
  ":focus": {
    outline: "none",
    boxShadow: `0 0 0 0.1rem ${colors.green2_50p}, ${defBoxShadow}`,
  },
}));

const redStyle = css({
  backgroundColor: colors.red,
  ":hover": {backgroundColor: colors.red},
  ":focus": {
    boxShadow: `0 0 0 0.1rem ${colors.red}, ${defBoxShadow}`,
  },
});

const IconButton = ({icon: Comp, isRed, ...rest}) => (
  <IconButtonContainer css={[isRed && redStyle]} {...rest}>
    <Comp color="white" size={5} />
  </IconButtonContainer>
);

export default IconButton;

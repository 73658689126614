import React from "react";
import {css} from "@emotion/core";
import colors from "./colors";
import {addClasses, classes} from "./classes";
import mq from "./media-queries";

const spacingSteps = [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4, 6];
const fontSizes = [10 / 16, 12 / 16, 14 / 16, 1, 18 / 16, 22 / 16, 32 / 16];

addClasses(["paddingTop", "paddingLeft", "paddingRight", "paddingBottom"], spacingSteps);
addClasses(["backgroundColor"], colors);
addClasses(["alignItems"], {
  start: "flex-start",
  center: "center",
  end: "flex-end",
  baseline: "baseline",
});
addClasses(["justifyContent"], {start: "flex-start", center: "center", end: "flex-end"});
addClasses(["boxShadow"], {
  1: "0 0.1rem 0.1rem rgba(0,0,0,0.1), 0 0.1rem 0.5rem rgba(0,0,0,0.05)",
  2: "0 0.1rem 0.2rem rgba(0,0,0,0.1), 0 0.2rem 1rem rgba(0,0,0,0.2)",
});
addClasses(["flex"], {auto: "auto", none: "none"});

const propsToCss = props => {
  const {
    css: cssList = [],
    pa,
    px = pa,
    py = pa,
    pt = py,
    pl = px,
    pr = px,
    pb = py,
    bg,
    elevation,
    fillParent,
    noShrink,
    as: _,
    ...rest
  } = props;
  if (pt !== null && pt !== undefined) cssList.push(classes.paddingTop[pt]);
  if (pb !== null && pb !== undefined) cssList.push(classes.paddingBottom[pb]);
  if (pl !== null && pl !== undefined) cssList.push(classes.paddingLeft[pl]);
  if (pr !== null && pr !== undefined) cssList.push(classes.paddingRight[pr]);
  if (bg) cssList.push(classes.backgroundColor[bg]);
  if (elevation) cssList.push(classes.boxShadow[elevation]);
  if (fillParent) cssList.push(classes.flex.auto);
  if (noShrink) cssList.push(classes.flex.none);

  return {css: cssList, ...rest};
};

const pushClass = css({flex: "auto"});

export const Push = props => <div css={pushClass} {...props} />;

addClasses(["vertSpacing"], spacingSteps, val => ({
  [`> *:not(:last-child):not(.css-${pushClass.name})`]: {marginRight: `${val}rem`},
}));

const rowClass = css({display: "flex", flexDirection: "row"});
addClasses(["breakVertSpacing"], spacingSteps, val => ({
  [mq.mobile]: {
    flexDirection: "column",
    [`> *:not(:last-child):not(.css-${pushClass.name})`]: {
      marginRight: 0,
      marginBottom: `${val}rem`,
    },
  },
}));

const rowCss = props => {
  const {css: cssList = [], sp, align, justify, breaking, ...rest} = props;
  cssList.push(rowClass);
  if (sp !== undefined && sp !== null) cssList.push(classes.vertSpacing[sp]);
  if (breaking || breaking === 0) {
    cssList.push(classes.breakVertSpacing[typeof breaking === "number" ? breaking : sp]);
  }
  if (align) cssList.push(classes.alignItems[align]);
  if (justify) cssList.push(classes.justifyContent[justify]);
  return {css: cssList, ...rest};
};

export const Row = React.forwardRef((props, ref) => {
  const Comp = props.as || "div";
  return <Comp ref={ref} {...propsToCss(rowCss(props))} />;
});

addClasses(["horSpacing"], spacingSteps, val => ({
  [`> *:not(:last-child):not(.css-${pushClass.name})`]: {marginBottom: `${val}rem`},
}));

const colClass = css({display: "flex", flexDirection: "column"});
const colCss = props => {
  const {css: cssList = [], sp, align, justify, ...rest} = props;
  cssList.push(colClass);
  if (sp !== undefined && sp !== null) cssList.push(classes.horSpacing[sp]);
  if (align) cssList.push(classes.alignItems[align]);
  if (justify) cssList.push(classes.justifyContent[justify]);
  return {css: cssList, ...rest};
};

export const Col = React.forwardRef((props, ref) => {
  const Comp = props.as || "div";
  return <Comp ref={ref} {...propsToCss(colCss(props))} />;
});

addClasses(["gridSpacing"], spacingSteps, val => ({
  marginLeft: `${-val / 2}rem`,
  marginRight: `${-val / 2}rem`,
  marginBottom: `${-val}rem`,
  [`> *`]: {
    marginLeft: `${val / 2}rem`,
    marginRight: `${val / 2}rem`,
    marginBottom: `${val}rem`,
  },
}));
const gridClass = css({display: "flex", flexDirection: "row", flexWrap: "wrap"});

export const Grid = React.forwardRef(({children, sp, ...rest}, ref) => {
  const cssList = [gridClass];
  if (sp !== undefined && sp !== null) cssList.push(classes.gridSpacing[sp]);
  return (
    <div ref={ref} {...propsToCss(rest)}>
      <div css={cssList}>{children}</div>
    </div>
  );
});

const fontFamilies = {
  default: "'Source Sans Pro', sans-serif",
};
const textTypes = {
  body: {
    fontFamily: fontFamilies.default,
  },
  bodyBold: {
    fontFamily: fontFamilies.default,
    fontWeight: 700,
  },
  semiBold: {
    fontFamily: fontFamilies.default,
    fontWeight: 600,
  },
};

const textPresets = {
  button: {
    color: "white",
    size: 3,
    type: "semiBold",
    lineHeight: "tight",
  },
  body: {
    color: "blue2",
    size: 3,
    type: "body",
    lineHeight: "default",
  },
  semiBold: {
    color: "blue2",
    size: 3,
    type: "semiBold",
    lineHeight: "tight",
  },
  bold: {
    color: "blue2",
    size: 3,
    type: "bodyBold",
    lineHeight: "tight",
  },
};

addClasses(["color"], colors);
addClasses(["fontSize"], fontSizes);
addClasses(["lineHeight"], {tight: 1.1, default: 1.3125, wide: 1.5}, val => ({lineHeight: val}));
addClasses(["textAlign"], {center: "center", left: "left", right: "right"});
addClasses(["textTypes"], textTypes, val => val);

export const Text = React.forwardRef((props, ref) => {
  const preset = textPresets[props.preset] || textPresets.body;
  const {
    as: Comp = "div",
    preset: _,
    color = preset.color,
    size = preset.size,
    type = preset.type,
    lineHeight = preset.lineHeight,
    align = preset.align,
    passThrough,
    ...rest
  } = props;
  const cssList = [classes.fontSize[size], classes.textTypes[type]];
  if (lineHeight) cssList.push(classes.lineHeight[lineHeight]);
  if (align) cssList.push(classes.textAlign[align]);
  if (color) {
    cssList.push(classes.color[color]);
  }
  return <Comp ref={ref} css={cssList} {...rest} {...passThrough} />;
});

import React from "react";
import {Col, Text} from "../../components/ui";
import DefaultLayout from "../../components/ui/DefaultLayout";
import TitleBar from "../../components/ui/TitleBar";
import TransparentButton from "../../components/ui/TransparentButton";
import {useTaskById, useTaskSetter} from "../../models/tasks";
import NotFoundScreen from "../NotFound";
import {CardWithHeader} from "../../components/ui/Card";
import {format} from "date-fns";
import useStoToBrunnen from "../../lib/hooks/useStoToBrunnen";
import formatNumber from "../../lib/format-number";
import {getEinbautiefe, getDeckung} from "../../lib/brunnen-utils";
import {RadioButtonWithLabel} from "../../components/RadioButton";
import Button from "../../components/ui/Button";
import CommentSection from "../../components/Comment";
import {getMissingMeasurementCount} from "../../lib/task-utils";
import {IconArrowBack} from "../../components/ui/icons";
import {FormHeader, Field, Hint, PlausiHint, useAvgQ} from "./shared";

const DoVerifiedErgiebigkeitsmessungScreen = ({task, navigate}) => {
  const missingMeasurements = getMissingMeasurementCount(task);
  const setTask = useTaskSetter();

  const handleCommentChange = React.useCallback(
    comment => setTask(task.id, {...task, finalComment: comment}),
    [task, setTask]
  );

  const setQuestion = (name, value) => {
    setTask(task.id, {
      ...task,
      questions: {...task.questions, [name]: value},
    });
  };

  const title = (
    <TitleBar
      title="Ergiebigkeitsmessung"
      left={
        <TransparentButton
          noTextOnMobile
          onDark
          to="/tasks/brunnen"
          leftIcon={<IconArrowBack color="white" />}
        >
          Zurück
        </TransparentButton>
      }
    />
  );

  const brunnen = useStoToBrunnen()[task.brunnenSTO];
  const lm = brunnen && brunnen.LAST_MESS;
  const td = brunnen && brunnen.TECH_DATEN;

  const {Q_MENGE, BETR_H, Q_AVG} = useAvgQ(brunnen) || {};

  const druckNorm =
    brunnen && brunnen.NAME.startsWith("KAU") ? {min: 3, max: 10} : {min: 1, max: 6};

  const qSerializer = React.useMemo(
    () => ({
      get: v => v && v.value,
      set: v => (v !== null ? {value: v, type: "relative", prev: lm && lm.Q_NEU} : null),
    }),
    [lm]
  );

  if (!brunnen) {
    return (
      <DefaultLayout title={title}>
        <Text>Kein Zugriff zu diesem Brunnen</Text>
      </DefaultLayout>
    );
  }

  const lmDateStr = lm && format(lm.MESS_DAT, "DD.MM.YYYY");
  const depth = getEinbautiefe(brunnen);
  const deckung = getDeckung(brunnen);

  const missingMessage =
    missingMeasurements > 0
      ? `Messung oder Kommentar fehlt für ${
          missingMeasurements === 1 ? "ein Feld" : `${missingMeasurements} Felder`
        }`
      : null;

  return (
    <DefaultLayout
      title={title}
      headerProps={{pb: 2}}
      header={
        <FormHeader brunnen={brunnen} measurementsLeft={missingMeasurements} isDone={task.done} />
      }
    >
      <Col sp={5}>
        <Col sp={3}>
          <Field
            name="zaehlerstand"
            label="Wasserzählerstand"
            postfix="m³"
            task={task}
            setTask={setTask}
            serializer={qSerializer}
          >
            <PlausiHint name="zaehlerstand" task={task} shown={val => lm && val.value < lm.Q_NEU}>
              Der Zählerstand darf nicht geringer sein als der letzte Wert. Ausnahmen:
              Zählerwechsel, Rückfluss. Dann bitte Kommentar hinzufügen.
            </PlausiHint>
            <PlausiHint
              name="zaehlerstand"
              task={task}
              shown={val =>
                td &&
                Q_AVG &&
                !(lm && val.value < lm.Q_NEU) &&
                (Q_AVG < td.Q_N_M3_H * 0.5 || Q_AVG > td.Q_N_M3_H * 1.5)
              }
            >
              Der mittlere Volumenstrom (Qmittel={formatNumber(Q_AVG, {maximumFractionDigits: 1})}{" "}
              m³/h berechnet aus Wasserzählerstandsdifferenz={Q_MENGE} m³ und Betriebsstunden=
              {BETR_H}h) weicht deutlich von der Nennleistung der Pumpe ab (Qnenn={" "}
              {td && td.Q_N_M3_H} m³/h).
            </PlausiHint>
            {lm && (
              <Hint label={`Letzter Wert (${lmDateStr})`}>
                {formatNumber(lm.Q_NEU, {useGrouping: true})} m³
              </Hint>
            )}
            {lm && <Hint label="Zählernummer">{lm.ZAEHLER_NR}</Hint>}
          </Field>
          <Field
            name="momVolumen"
            label="Momentaner Volumenstrom"
            postfix="m³/h"
            task={task}
            setTask={setTask}
          >
            <PlausiHint
              name="momVolumen"
              task={task}
              shown={val =>
                td && td.Q_N_M3_H && (val > td.Q_N_M3_H * 1.5 || val < td.Q_N_M3_H * 0.5)
              }
            >
              Der eingegebene Volumenstrom weicht deutlich von der Nennleistung der Pumpe ab (Qnenn={" "}
              {td && td.Q_N_M3_H} m³/h).
            </PlausiHint>
            {lm && <Hint label={`Letzter Wert (${lmDateStr})`}>{formatNumber(lm.Q_MOM)} m³</Hint>}
          </Field>
          <Field
            name="druck"
            label="Druck am Manometer"
            postfix="bar"
            task={task}
            setTask={setTask}
            minimumFractionDigits={1}
          >
            <PlausiHint
              name="druck"
              task={task}
              shown={val => val < druckNorm.min || val > druckNorm.max}
            >
              Der eingegeben Druck liegt außerhalb des Normalbereiches ({druckNorm.min} bis{" "}
              {druckNorm.max} bar).
            </PlausiHint>
            <PlausiHint
              name="druck"
              task={task}
              shown={val =>
                lm &&
                (val < lm.DRUCK * 0.5 || val > lm.DRUCK * 1.5) &&
                !(val < druckNorm.min || val > druckNorm.max)
              }
            >
              Der eingegebene Druck weicht deutlich vom letzten Messwert ab.
            </PlausiHint>
            {lm && (
              <Hint label={`Letzter Wert (${lmDateStr})`}>
                {formatNumber(lm.DRUCK, {minimumFractionDigits: 1})} bar
              </Hint>
            )}
          </Field>
          <Field
            name="wasserstand"
            label="Wasserstand im Innenpegel"
            postfix="m"
            task={task}
            setTask={setTask}
            minimumFractionDigits={2}
          >
            <PlausiHint
              name="wasserstand"
              task={task}
              shown={val => val < 2 || (depth && val > depth)}
            >
              Der eingegebene Wasserstand weicht vom Normalbereich ab.
            </PlausiHint>
            <PlausiHint
              name="wasserstand"
              task={task}
              shown={val => deckung && val < depth && depth - val < 2}
            >
              Deckungsmangel!
            </PlausiHint>
            {task.measurements.wasserstand.value && deckung && (
              <Hint label="Aktuelle Deckung">
                {formatNumber(depth - task.measurements.wasserstand.value)} m
              </Hint>
            )}
            {depth && <Hint label="Einbautiefe Pumpe">{formatNumber(depth)} m</Hint>}
            {lm && lm.WSP_I && (
              <Hint label={`Letzter Wert (${lmDateStr})`}>{formatNumber(lm.WSP_I)} m</Hint>
            )}
            {deckung && <Hint label={`Deckung (${lmDateStr})`}>{formatNumber(deckung)} m</Hint>}
          </Field>
          {"tempPumpe" in task.measurements && (
            <Field
              name="tempPumpe"
              label="Temperatur Motor"
              postfix="°C"
              task={task}
              setTask={setTask}
              minimumFractionDigits={1}
            >
              {lm && lm.TEMPERATUR_PUMPE && (
                <Hint label={`Letzter Wert (${lmDateStr})`}>
                  {formatNumber(lm.TEMPERATUR_PUMPE, {minimumFractionDigits: 1})} °C
                </Hint>
              )}
            </Field>
          )}
          {"drehzahlPumpe" in task.measurements && (
            <Field
              name="drehzahlPumpe"
              label="Aktuelle Drehzahl der Pumpe"
              postfix={
                <>
                  min<sup>-1</sup>
                </>
              }
              task={task}
              setTask={setTask}
            >
              {lm && lm.DREHZAHL_PUMPE && (
                <Hint label={`Letzter Wert (${lmDateStr})`}>
                  {formatNumber(lm.DREHZAHL_PUMPE)} min<sup>-1</sup>
                </Hint>
              )}
              <PlausiHint name="drehzahlPumpe" task={task} shown={val => val < 1500 || val > 3500}>
                Die eingegebene Drehzahl liegt außerhalb des Normalbereiches. (1500 bis 3500) min
                <sup>-1</sup>
              </PlausiHint>
            </Field>
          )}
          <CardWithHeader header="Sonstige Angaben">
            <Col sp={3}>
              <RadioButtonWithLabel
                disabled={task.done}
                label="Brunnenstube sauber?"
                value={task.questions.sauber}
                onChange={e => setQuestion("sauber", e.target.value)}
              />
              <RadioButtonWithLabel
                disabled={task.done}
                value={task.questions.wasserImSchacht}
                onChange={e => setQuestion("wasserImSchacht", e.target.value)}
                label="Funktionsprüfung: Befindet sich Wasser im Schacht?"
              />
              <RadioButtonWithLabel
                disabled={task.done}
                value={task.questions.schwimmschalterFunktioniert}
                onChange={e => setQuestion("schwimmschalterFunktioniert", e.target.value)}
                label="Funktioniert der Schwimmschalter?"
              />
              <RadioButtonWithLabel
                disabled={task.done}
                value={task.questions.deckelkontaktFunktioniert}
                onChange={e => setQuestion("deckelkontaktFunktioniert", e.target.value)}
                label="Funktioniert der Deckelkontakt?"
              />
            </Col>
          </CardWithHeader>
          <CardWithHeader header="Abschließende Beurteilung">
            <CommentSection
              readOnly={task.done}
              comment={task.finalComment}
              onChange={handleCommentChange}
            />
          </CardWithHeader>
        </Col>

        <Col align="center" sp={4}>
          {missingMessage && (
            <Col px={3} py={1} bg="blue8" elevation={1} css={{borderRadius: "0.2rem"}}>
              <Text preset="bold" size={2} color="blue1">
                {missingMessage}
              </Text>
            </Col>
          )}
          <Button to="/tasks/brunnen">Zur Übersicht</Button>
        </Col>
      </Col>
    </DefaultLayout>
  );
};

const ErgFormScreen = ({taskId, navigate, uri}) => {
  const maybeTask = useTaskById(taskId);
  if (maybeTask.isLoading) {
    return "loading";
  } else if (!maybeTask.value) {
    return <NotFoundScreen />;
  } else {
    const task = maybeTask.value;
    return <DoVerifiedErgiebigkeitsmessungScreen task={task} navigate={navigate} />;
  }
};

export default ErgFormScreen;

export const getEinbautiefe = brunnen => {
  const td = brunnen.TECH_DATEN;
  return td && td.EINLAUFSIEB_M_URA
    ? brunnen.MESSPUNKT_M_NHN - brunnen.ROHRACHSE_M_NHN + td.EINLAUFSIEB_M_URA
    : null;
};

export const getDeckung = brunnen => {
  const e = getEinbautiefe(brunnen);
  const lm = brunnen.LAST_MESS;
  return lm && lm.WSP_I && e ? e - lm.WSP_I : null;
};

import React from "react";
import styled from "@emotion/styled";
import {Text, Row} from ".";
import {ButtonOrLinkComp, rawButtonStyles} from "./buttonHelpers";
import mq from "./media-queries";
import css from "@emotion/css";

const TransparentButtonContainer = styled(ButtonOrLinkComp, {
  shouldForwardProp: p => p !== "onDark",
})(rawButtonStyles, ({onDark, disabled}) => ({
  transitionProperty: "background-color, border-color",
  borderRadius: "0.2rem",
  background: "transparent",
  padding: "0.1rem 0.4rem",
  margin: "-0.1rem -0.4rem",
  border: "1px solid transparent",
  ":hover": !disabled && {
    backgroundColor: onDark ? "rgba(255,255,255,0.20)" : "rgba(0,0,0,0.025)",
  },
  ":focus": {
    borderColor: onDark ? "rgba(255,255,255,0.4)" : "rgba(0,0,0,0.1)",
    backgroundColor: onDark ? "rgba(255,255,255,0.20)" : "rgba(0,0,0,0.025)",
    outline: "none",
  },
}));

const textStyles = {
  default: css({position: "relative", top: "0.1em"}),
  noTextOnMobile: css({
    [mq.mobile]: {display: "none"},
  }),
};

const TransparentButton = ({
  children,
  onDark,
  leftIcon,
  rightIcon,
  textProps,
  textCss,
  noTextOnMobile,
  ...rest
}) => (
  <TransparentButtonContainer onDark={onDark} {...rest}>
    <Row align="center" sp={2}>
      {leftIcon}
      {typeof children === "string" ? (
        <Text
          size={2}
          color={onDark ? "blue8" : "blue2"}
          preset="button"
          css={[textStyles.default, textCss, noTextOnMobile && textStyles.noTextOnMobile]}
          {...textProps}
        >
          {children}
        </Text>
      ) : (
        children
      )}
      {rightIcon}
    </Row>
  </TransparentButtonContainer>
);

export default TransparentButton;
